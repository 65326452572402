import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loader from '../common/Loader';
import { TabTitle } from "../../utils/General";

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("submitting");

        try {
            // Send email using an API or server-side component
            await sendEmail({ email, fullname, subject, message });

            // Email sent successfully
            setStatus("SuccessEmail");
            setStatusMessage("Email sent. We will get back to you in 48 hours max. Thank you for your email 🚀")
        } catch (error) {
            // Error occurred while sending the email
            setStatus("ErrorEmail");
        }

    };

    TabTitle("Louno Space | Contact us");


    const sendEmail = async (emailData) => {
        try {
            const response = await fetch('/api/v1/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailData),
            });

            if (response.ok) {
                return true; // Email sent successfully
            } else {
                throw new Error('Failed to send email');
            }
        } catch (error) {
            throw error;
        }
    };

    return (
        <>
            <div className="isolate bg-education-white">
                {loading && <Loader />}
                {!loading && <Header />}
                {!loading && (
                    <>
                        <section className="bg-louno-white">
                            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                                <h2 className="mb-4 text-4xl tracking-tight font-chillax_medium text-center text-louno-black">Contact Us</h2>
                                <p className="mb-8 lg:mb-16 font-light text-center font-synonym_regular text-louno-space sm:text-xl">Got a question about our courses? Want us at your school/organisation? <br />Want to collab with us? Let us know how we can help you.</p>
                                <form onSubmit={handleSubmit} className="space-y-8">
                                    <div>
                                        <label htmlFor="fullname" className="block mb-2 text-sm font-medium text-louno-black ">Full Name</label>
                                        <input
                                            type="text"
                                            id="fullname"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-louno-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                            placeholder="John Doe"
                                            value={fullname}
                                            onChange={(e) => setFullname(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-louno-black ">Your email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-louno-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                            placeholder="name@louno.space"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="subject" className="block mb-2 text-sm font-medium text-louno-black ">Subject</label>
                                        <input
                                            type="text"
                                            id="subject"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-louno-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                            placeholder="Let us know how we can help you"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-louno-black ">Your message</label>
                                        <textarea
                                            id="message"
                                            rows="6"
                                            className="block p-2.5 w-full text-sm text-louno-black bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                            placeholder="Leave a comment..."
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <button
                                        type="submit"
                                        className="bg-louno-white border-gray-300 border rounded-md w-full h-auto uppercase font-chillax_regular text-sm text-louno-black mt-5 p-3 hover:bg-[#38384b] hover:text-[#e4cac9] duration-100"
                                        disabled={loading}
                                    >
                                        {status == "submitting" ? <img src='./assets/images/louno-loader-white.gif' className="w-10 text-center align-middle items-center justify-center inline" /> : 'Send email'}
                                    </button>
                                    {status == 'SuccessEmail' && <p className="text-education-ming text-sm">{statusMessage}</p>}
                                </form>
                            </div>
                        </section>
                    </>
                )}
                {!loading && <Footer />}
            </div>
        </>
    );
};

export default Contact;
