import React, { useState } from 'react';
import Datepicker from "tailwind-datepicker-react"

const DatePickerComponent = ({ show, onClose, onFocus }) => {
    const today = new Date();
    const options = {
        autoHide: true,
        todayBtn: false,
        clearBtn: true,
        clearBtnText: "Clear",
        maxDate: new Date("2030-01-01"),
        minDate: new Date("1950-01-01"),
        theme: {
            background: "bg-white",
            todayBtn: "",
            clearBtn: "",
            icons: "",
            text: "",
            input: "",
            inputIcon: "",
            selected: "",
        },
        icons: {
            // () => ReactElement | JSX.Element
            prev: () => <span>Previous</span>,
            next: () => <span>Next</span>,
        },
        datepickerClassNames: "top-12",
        defaultDate: new Date(today),
        language: "en",
        disabledDates: [],
        weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        inputNameProp: "date",
        inputIdProp: "date",
        inputPlaceholderProp: "Select Date",
        inputDateFormatProp: {
            day: "numeric",
            month: "long",
            year: "numeric"
        }
    }

    const [isShow, setShow] = useState(show);

    const handleChange = (selectedDate) => {
        console.log(selectedDate);
    };

    const handleClose = (state) => {
        setShow(state);
    };

    return (
        <div>
            <Datepicker options={options} onChange={handleChange} show={show} setShow={onClose} />
        </div>
    );
};

export default DatePickerComponent;