import React from "react";
import { Helmet } from "react-helmet";

const GoogleTag = () => {
  return (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-66C9LPYT24"></script>
      <script>
        {
          `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P34KJJ9D');
  window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-66C9LPYT24');`
        }</script>
      <noscript id="facebook-pixel-image">
        {`
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P34KJJ9D"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        `}</noscript>
    </Helmet>
  );
};

export default GoogleTag;
