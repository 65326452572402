import React, { useState, useEffect } from 'react';

const regularChallenges = [
    'Do jumping jacks',
    'Run in place',
    'Touch your toes',
    'Hop on one foot',
    'Move your head in a circle',
    'Clap your hands',
    'Do a silly dance',
    'Pretend to be a robot',
    'Spin around in a circle',
    'Jump up and down',
    'Shake your arms',
    'Do high knees',
];

const accessibleChallenges = [
    'Touch your toes while seated',
    'Rotate your wrists',
    'Raise your arms above your head',
    'Stretch your fingers',
    'Breathe deeply',
    'Reach for the sky',
    'Open and close your mouth',
    'Roll your shoulders',
    'Raise and lower your eyebrows',
];

const generateRandomSequenceLength = (level) => {
    if (level < 7) {
        return Math.floor(Math.random() * 4) + 2; // Up to level 7, sequence length between 2 to 5
    } else if (level < 15) {
        return Math.floor(Math.random() * 5) + 1; // Up to level 15, sequence length between 1 to 5
    } else {
        return level - 14; // After level 15, sequence length increases by 1 each level
    }
};

const generateSequence = (length) => {
    return Array.from({ length }, () => Math.floor(Math.random() * 5) + 1); // Numbers from 1 to 5 for simplicity
};

const saveScore = (scoreData) => {
    const scores = JSON.parse(localStorage.getItem('scores')) || [];
    scoreData.id = scores.length > 0 ? scores[scores.length - 1].id + 1 : 1; // Assign unique ID
    scores.push(scoreData);
    localStorage.setItem('scores', JSON.stringify(scores));
};

const getScores = () => {
    return JSON.parse(localStorage.getItem('scores')) || [];
};

const exportToCSV = () => {
    const scores = getScores();

    // Simplified CSV
    const csvContentSimplified = 'data:text/csv;charset=utf-8,' +
        'ID,Age,Level,Total Time (seconds),Date,Time\n' +
        scores.map(score => {
            return `${score.id},${score.age},${score.level},${(score.totalTime / 1000).toFixed(2)},${new Date(score.date).toLocaleDateString()},${new Date(score.date).toLocaleTimeString()}`;
        }).join('\n');

    const encodedUriSimplified = encodeURI(csvContentSimplified);
    const linkSimplified = document.createElement('a');
    linkSimplified.setAttribute('href', encodedUriSimplified);
    linkSimplified.setAttribute('download', 'scores_simplified.csv');
    document.body.appendChild(linkSimplified);
    linkSimplified.click();
    document.body.removeChild(linkSimplified);

    // Detailed CSV
    const csvContentDetailed = 'data:text/csv;charset=utf-8,' +
        'ID,Level,Sequence,Challenge,Time to Memorise (seconds),Time to Answer (seconds),Total Time (seconds)\n' +
        scores.map(score => {
            return `${score.id},${score.level},${score.sequence},${score.challenge},${(score.memorisationTime / 1000).toFixed(2)},${(score.answerTime / 1000).toFixed(2)},${(score.totalTime / 1000).toFixed(2)}`;
        }).join('\n');

    const encodedUriDetailed = encodeURI(csvContentDetailed);
    const linkDetailed = document.createElement('a');
    linkDetailed.setAttribute('href', encodedUriDetailed);
    linkDetailed.setAttribute('download', 'scores_detailed.csv');
    document.body.appendChild(linkDetailed);
    linkDetailed.click();
    document.body.removeChild(linkDetailed);
};

const beep = () => {
    const audio = new Audio('https://www.soundjay.com/button/beep-07.wav');
    audio.play();
};

function Challenge() {
    const [age, setAge] = useState('');
    const [playing, setPlaying] = useState(false);
    const [sequence, setSequence] = useState([]);
    const [level, setLevel] = useState(0);
    const [scores, setScores] = useState(getScores());
    const [showChallenge, setShowChallenge] = useState(false);
    const [showSequence, setShowSequence] = useState(false);
    const [typedSequence, setTypedSequence] = useState('');
    const [feedback, setFeedback] = useState('');
    const [successfulSequences, setSuccessfulSequences] = useState(0);
    const [accessibleMode, setAccessibleMode] = useState(false); // Toggle for accessible mode

    const [memorisationStartTime, setMemorisationStartTime] = useState(0);
    const [answerStartTime, setAnswerStartTime] = useState(0);
    const [memorisationTime, setMemorisationTime] = useState(0);
    const [answerTime, setAnswerTime] = useState(0);
    // const [countdown, setCountdown] = useState(5); // Initial countdown duration

    useEffect(() => {
        setScores(getScores());
    }, [playing]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === ' ' && showChallenge) {
                setShowChallenge(false);
                setShowSequence(true);
                setMemorisationTime(Date.now() - memorisationStartTime); // Record memorisation time
                setAnswerStartTime(Date.now()); // Start answer time
                beep();
            } else if (event.key >= '0' && event.key <= '9' && showSequence) {
                setTypedSequence((prev) => prev + event.key);
            } else if (event.key === 'Backspace' && showSequence) {
                setTypedSequence((prev) => prev.slice(0, -1));
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [showChallenge, showSequence]);

    const baseDuration = 5; // Base countdown duration (adjustable)
    const levelAdjustment = 0.33; // Factor to decrease duration by (1/3)

    // useEffect(() => {
    //     let timer;
    //     if (showChallenge && countdown > 0) {
    //         timer = setTimeout(() => {
    //             setCountdown(prev => prev - 1);
    //         }, level < 3 ? 2500 : level < 7 ? 2000 : 1500); // Adjust countdown duration based on level
    //     } else if (showChallenge && countdown === 0) {
    //         setShowChallenge(false);
    //         setShowSequence(true);
    //         setMemorisationTime(Date.now() - memorisationStartTime); // Record memorisation time
    //         setAnswerStartTime(Date.now()); // Start answer time
    //         beep();
    //     }

    //     return () => clearTimeout(timer);
    // }, [showChallenge, countdown, level, memorisationStartTime]);

    // useEffect(() => {
    //     let timer;
    //     if (showChallenge && countdown > 0) {
    //         timer = setTimeout(() => {
    //             setCountdown(prev => prev - 1);
    //         }, 1000); // Update countdown every second
    //     } else if (showChallenge && countdown === 0) {
    //         setShowChallenge(false);
    //         setShowSequence(true);
    //         setMemorisationTime(Date.now() - memorisationStartTime); // Record memorisation time
    //         setAnswerStartTime(Date.now()); // Start answer time
    //         beep();
    //     }

    //     return () => clearTimeout(timer);
    // }, [showChallenge, countdown, level]);

    const startGame = () => {
        setPlaying(true);
        setLevel(0);
        setSequence(generateSequence(generateRandomSequenceLength(0))); // Start with random sequence length
        setShowChallenge(true);
        setSuccessfulSequences(0);
        setMemorisationStartTime(Date.now());
        setTypedSequence('');
        //setCountdown(5); // Reset countdown duration
    };

    const nextLevel = () => {
        let nextLength = generateRandomSequenceLength(level + 1);
        if (successfulSequences >= 4) {
            nextLength++;
        }
        setSequence(generateSequence(nextLength > 5 ? 5 : nextLength)); // Limit sequence length to 5
        setLevel(level + 1);
        setTypedSequence('');
        setShowChallenge(true);
        setSuccessfulSequences(prev => prev + 1);
        setMemorisationStartTime(Date.now());
        // const baseDuration = 5; // Base countdown duration (adjustable)
        // const levelAdjustment = 0.33; // Factor to decrease duration by (1/3)
        // const calculateCountdown = () => {
        //     const adjustedDuration = Math.max(
        //         baseDuration - (level * levelAdjustment),
        //         1 // Minimum duration of 1 second
        //     );
        //     setCountdown(adjustedDuration);
        // };
    };

    useEffect(() => {
        if (showSequence && typedSequence.length === sequence.length) {
            setAnswerTime(Date.now() - answerStartTime); // Record answer time

            const checkTypedSequence = () => {
                const correctSequence = sequence.join('');
                if (typedSequence === correctSequence) {
                    setFeedback('🎉 Correct!');
                    setTimeout(() => {
                        setFeedback('');
                        nextLevel();
                    }, 2500);
                } else {
                    setFeedback('Oh no! That was wrong... 😢');
                    setTimeout(() => {
                        saveScore({
                            age,
                            level,
                            date: new Date().toISOString(),
                            sequence: sequence.join(','),
                            challenge: accessibleMode ? accessibleChallenges[level % accessibleChallenges.length] : regularChallenges[level % regularChallenges.length],
                            memorisationTime,
                            answerTime,
                            totalTime: memorisationTime + answerTime,
                            accessibleMode,
                        });
                        setPlaying(false);
                        setFeedback('');
                    }, 2500);
                }
            };

            checkTypedSequence();
        }
    }, [typedSequence]);

    const resetGameState = () => {
        setAge('');
        setSequence([]);
        setLevel(0);
        setTypedSequence('');
        setShowChallenge(false);
        setShowSequence(false);
        setFeedback('');
        setAccessibleMode(false);
    };

    const handleEndGame = () => {
        saveScore({
            age,
            level,
            sequence: sequence.join(','),
            challenge: accessibleMode ? accessibleChallenges[level % accessibleChallenges.length] : regularChallenges[level % accessibleChallenges.length],
            memorisationTime,
            answerTime,
            totalTime: memorisationTime + answerTime,
            date: new Date().toISOString(),
            accessibleMode,
        });
        setPlaying(false);
    };

    if (!playing) {
        return (
            <>
                <div className="bg-education-main/10 flex justify-center items-center">
                    <a href="/">
                        <img src="/assets/images/Louno_Space_logo.png" className="w-56" alt="Logo" />
                    </a>
                </div>
                <div className="min-h-screen flex flex-col items-center justify-center bg-education-main/10 p-4">
                    <h1 className="text-3xl font-bold mb-6">Astronaut Challenge</h1>
                    <input
                        type="number"
                        name="age"
                        value={age}
                        onChange={e => setAge(e.target.value)}
                        placeholder="Enter your age"
                        className="mb-4 p-2 border border-gray-300 rounded-md"
                    />
                    <label className="flex items-center mb-4">
                        <input
                            type="checkbox"
                            checked={accessibleMode}
                            onChange={() => setAccessibleMode(prev => !prev)}
                            className="mr-2"
                        />
                        Accessible Mode
                    </label>
                    <button
                        onClick={resetGameState}
                        className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition mt-4"
                    >
                        Reset Game
                    </button>
                    <button
                        onClick={startGame}
                        className="bg-education-pink-barbie text-white py-2 px-4 rounded-md hover:bg-education-ming transition"
                    >
                        Play
                    </button>

                    <div className="mt-10 w-full max-w-2xl">
                        <h2 className="text-2xl font-bold mb-4">Scoreboard</h2>
                        <ul className="bg-white shadow-md rounded-lg p-4">
                            {scores.sort((a, b) => b.level - a.level).map((score, index) => (
                                <li
                                    key={index}
                                    className={`mb-2 p-2 border-b border-gray-200 flex justify-between items-center ${index === 0 ? 'bg-yellow-200' : index === 1 ? 'bg-yellow-300' : index === 2 ? 'bg-yellow-400' : ''}`}
                                >
                                    <span className="font-bold">Age: {score.age} - Level {score.level}</span>
                                    <br />
                                    <span className="font-bold">Total Time (seconds): {score.totalTime / 1000}</span>
                                </li>
                            ))}
                        </ul>
                        <button
                            onClick={exportToCSV}
                            className="bg-education-ming text-white py-2 px-4 rounded-md hover:bg-education-pink-barbie transition mt-4"
                        >
                            Download Scores as CSV
                        </button>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-education-main/10 p-4">
            {showChallenge && (
                <div className="text-center">
                    <h1 className="text-3xl font-bold mb-2">Level {level + 1}</h1>
                    <div className="text-4xl font-bold mb-6">
                        {accessibleMode ? accessibleChallenges[level % accessibleChallenges.length] : regularChallenges[level % regularChallenges.length]}
                    </div>
                    <div className="text-2xl mb-4">Memorise this sequence: {sequence.join(' ')}</div>
                    {/* <h2 className="text-xl mb-4">Countdown: {countdown}</h2> */}
                    <div className="text-lg text-gray-500">Press spacebar to proceed</div>
                </div>
            )}
            {showSequence && !showChallenge && (
                <div className="text-center">
                    <h1 className="text-3xl font-bold mb-6">Level {level + 1}</h1>
                    <div className="text-2xl mb-4">Type the sequence:</div>
                    <div className="flex justify-center items-center space-x-4 mb-4">
                        {sequence.map((num, index) => (
                            <div key={index} className="bg-gray-200 px-3 py-2 rounded-lg text-2xl font-bold">{typedSequence[index] || '_'}</div>
                        ))}
                    </div>
                    <div className="text-lg text-gray-500">Type the sequence of numbers</div>
                    {feedback && <div className="mt-4 text-xl">{feedback}</div>}
                </div>
            )}
            <button
                onClick={handleEndGame}
                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 mt-4"
            >
                End Game
            </button>
        </div>
    );
}

export default Challenge;
