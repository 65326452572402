import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../common/Footer';
import Header from '../common/Header'
import HeadTags from "../common/HeadTags";
import Cookies from 'js-cookie';
import Datepicker from "tailwind-datepicker-react"
import DatePickerComponent from '../../utils/Datepicker'
import BirthdayModal from '../../utils/BirthdayModal'
import FeedbackButton from '../../utils/FeedbackButton'

const BDay = () => {
    const page = "birthday-party-kids"
    const [message, setMessage] = useState("")
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        child_name: '',
        bday_date_t: '',
        number_of_kids: '',
        age_range_of_the_kids: '',
        location_party: '',
        event_duration: '',
        place_party: '',
        messagebday: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const [isBirthdayModalOpen, setBirthdayModalOpen] = useState(false);

    const openBirthdayModal = () => {
        setBirthdayModalOpen(true);
    };

    const closeBirthdayModal = () => {
        setBirthdayModalOpen(false);
    };

    const formv3 = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058//3d796370-6ef5-4d56-9441-7bb32f99efa1';

        // Example request JSON:
        var data = {
            submittedAt: Date.now(),
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: formData.email,
                },
                {
                    objectTypeId: "0-1",
                    name: "firstname",
                    value: formData.firstname,
                },
                {
                    objectTypeId: "0-1",
                    name: "lastname",
                    value: formData.lastname,
                },
                {
                    objectTypeId: "0-1",
                    name: "messagebday",
                    value: formData.messagebday,
                },
                {
                    objectTypeId: "0-1",
                    name: "child_name",
                    value: formData.child_name,
                },
                {
                    objectTypeId: "0-1",
                    name: "bday_date_t",
                    value: formData.bday_date_t,
                },
                {
                    objectTypeId: "0-1",
                    name: "number_of_kids",
                    value: formData.number_of_kids,
                },
                {
                    objectTypeId: "0-1",
                    name: "age_range_of_the_kids",
                    value: formData.age_range_of_the_kids,
                },
                {
                    objectTypeId: "0-1",
                    name: "location_party",
                    value: formData.location_party,
                },
                {
                    objectTypeId: "0-1",
                    name: "event_duration",
                    value: formData.event_duration,
                },
                {
                    objectTypeId: "0-1",
                    name: "at_your_place",
                    value: formData.place_party,
                },
                {
                    objectTypeId: "0-1",
                    name: "page",
                    value: page,
                },
            ],
            context: {
                hutk: Cookies.get('hubspotutk'),
            },
        };


        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage("Message sent. We will reply within 48 hours. Thank you 🚀");
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    const today = new Date();
    const options = {
        autoHide: true,
        todayBtn: false,
        clearBtn: true,
        clearBtnText: "Clear",
        maxDate: new Date("2030-01-01"),
        minDate: new Date("1950-01-01"),
        theme: {
            background: "bg-white",
            todayBtn: "",
            clearBtn: "",
            icons: "",
            text: "",
            input: "",
            inputIcon: "",
            selected: "",
        },
        icons: {
            // () => ReactElement | JSX.Element
            prev: () => <span>Previous</span>,
            next: () => <span>Next</span>,
        },
        datepickerClassNames: "top-12",
        defaultDate: new Date(today),
        language: "en",
        disabledDates: [],
        weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        inputNameProp: "date",
        inputIdProp: "date",
        inputPlaceholderProp: "Select Date",
        inputDateFormatProp: {
            day: "numeric",
            month: "long",
            year: "numeric"
        },
        position: "auto",
        offset: [0, 5],
    }

    const [show, setShow] = useState(false)
    const handleChange = (selectedDate) => {
        console.log(selectedDate)
    }
    const handleClose = (state) => {
        setShow(state)
    }

    return (
        <>
            <HeadTags pageTitle="STEM Kids Birthday Party " />
            <div class="relative isolate overflow-hidden bg-education-white h-auto">
                <Header />
                <FeedbackButton page="birthday"/>
                <div class="relative isolate overflow-hidden bg-white py-8 sm:py-12 mx-auto max-w-7xl">
                    <div class="px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2">
                        <div className="md:mr-4">
                            <div class="mx-auto max-w-2xl lg:mx-0">
                                <img src="/assets/images/build-rocket.png" className="rounded-xl" />
                            </div>
                        </div>
                        <div className="md:ml-8 mt-8 md:mt-0">
                            <h3
                                class="pt-4 mb-4 text-4xl font-bold tracking-tight text-education-space md:text-4xl font-chillax_medium align-bottom">
                                Host a Stellar Birthday Party</h3>
                            <p className="font-synonym_regular text-justify pt-4">
                                Is your child obsessed with stars, planets, and all things space?
                                <br />
                                At Louno Space, we turn birthday parties into unforgettable out-of-this-world adventures!  Led by real Space Engineers, our parties are packed with:
                            </p>
                            <p className="font-synonym_regular my-2">- <span className="font-synonym_medium text-education-main">Interactive workshops</span>: Explore space exploration, build rockets, and embark on exciting missions.</p>
                            <p className="font-synonym_regular my-2">- <span className="font-synonym_medium text-education-main">Hands-on activities</span>: Ignite creativity and critical thinking through fun and engaging games.</p>
                            <p className="font-synonym_regular my-2">- <span className="font-synonym_medium text-education-main">Educational fun</span>: Learn about space science while celebrating with friends and family.</p>
                            <p className="font-synonym_regular my-2">- <span className="font-synonym_medium text-education-main">Themed decorations and music</span>: Transform your party into a truly immersive space experience.</p>
                            <p className="font-chillax_medium mt-8 text-2xl text-education-pink-barbie">
                                Here's what makes our parties truly unique:
                            </p>
                            <p className="font-synonym_regular my-2">

                                <p className="font-synonym_regular my-2">- <span className="font-synonym_medium text-education-main">Expertise</span>: Learn from real Space Engineers and astronauts!</p>
                                <p className="font-synonym_regular my-2">- <span className="font-synonym_medium text-education-main">Customisable</span>: Choose a theme, activities, and duration to match your child's interests.</p>
                                <p className="font-synonym_regular my-2">- <span className="font-synonym_medium text-education-main">Stress-free for you</span>: We handle the planning, setup, and cleanup, leaving you free to enjoy the celebration.</p>
                                <p className="font-synonym_regular my-2">- <span className="font-synonym_medium text-education-main">Unforgettable memories</span>: Create a birthday party your child and their friends will never forget!</p>
                            </p>
                        </div>
                    </div>
                    <span className="hidden">stem kids party, kids birthday parties, kids party london, themes kids party london, space themes kids party</span>
                    <div class="px-6 lg:px-8 grid grid-cols-1 md:grid-cols-1 gap-x-10">
                        <div>
                            {/* <h1 className="text-5xl font-chillax_medium">Plan now</h1>
                            <p className="font-synonym_medium mt-2">Fill the form below and we will get in touch within 48 hours!</p> */}
                            {/* <form className="w-full mt-16" onSubmit={formv3}>
                                <div className="grid grid-cols-2 gap-x-10">
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="firstname" className="block text-gray-700 text-sm font-medium mb-2">
                                                First Name *
                                            </label>
                                            <input
                                                type="text"
                                                id="firstname"
                                                name="firstname"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.firstname}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="lastname" className="block text-gray-700 text-sm font-medium mb-2">
                                                Last Name *
                                            </label>
                                            <input
                                                type="text"
                                                id="lastname"
                                                name="lastname"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.lastname}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">
                                                Email Address *
                                            </label>
                                            <input
                                                type="text"
                                                id="email"
                                                name="email"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.email}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="child_name" className="block text-gray-700 text-sm font-medium mb-2">
                                                Your child's name *
                                            </label>
                                            <input
                                                type="text"
                                                id="child_name"
                                                name="child_name"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.child_name}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="bday_date_t" className="block text-gray-700 text-sm font-medium mb-2">
                                                Birthday date *
                                            </label>
                                            <input
                                                type="text"
                                                id="bday_date_t"
                                                name="bday_date_t"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.bday_date_t}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="number_of_kids" className="block text-gray-700 text-sm font-medium mb-2">
                                                Number of kids *
                                            </label>
                                            <input
                                                type="text"
                                                id="number_of_kids"
                                                name="number_of_kids"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.number_of_kids}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="age_range_of_the_kids" className="block text-gray-700 text-sm font-medium mb-2">
                                                Age range of the kids *
                                            </label>
                                            <input
                                                type="text"
                                                id="age_range_of_the_kids"
                                                name="age_range_of_the_kids"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.age_range_of_the_kids}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="event_duration" className="block text-gray-700 text-sm font-medium mb-2">
                                                Event duration *
                                                <p>Minimum 2 hours, maximu 5 hours</p>
                                            </label>
                                            <input
                                                type="text"
                                                id="event_duration"
                                                name="event_duration"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.event_duration}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="place_party" className="block text-gray-700 text-sm font-medium mb-2">
                                                Where would you like to host the workshop? (e.g. your place or school) *
                                            </label>
                                            <input
                                                type="text"
                                                id="place_party"
                                                name="place_party"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.place_party}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="location_party" className="block text-gray-700 text-sm font-medium mb-2">
                                                Where are you based? *
                                            </label>
                                            <input
                                                type="text"
                                                id="location_party"
                                                name="location_party"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.location_party}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="messagebday" className="block text-gray-700 text-sm font-medium mb-2">
                                                More details *
                                            </label>
                                            <textarea
                                                id="messagebday"
                                                name="messagebday"
                                                rows="4"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.messagebday}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full h-auto mt-8 bg-education-main py-4 text-sm font-bold uppercase tracking-widest text-white rounded-md"
                                        >
                                            Send request
                                        </button>

                                        <span className="text-xs">
                                            By submitting the form you agree to our <a href="policies">Terms and Conditions.</a>
                                        </span>

                                        <br />
                                        <span className="text-education-ming">{message}</span>
                                    </div>
                                </div>
                            </form> */}
                        </div>
                        <div className="mt-16 font-synonym_regular">
                            <div className="grid grid-cols-3">
                                <div className="col-span-2 mr-12">
                                    <h3 className="mb-4 font-chillax_medium text-education-main text-4xl">Space Party <span className="text-sm">(1h 30min)</span></h3> 
                                    <p><span className="font-synonym_medium text-education-main">Blast off to an out-of-this-world birthday bash at Louno Space!</span> This stellar party package is perfect for space adventurers aged 4-9.
                                        <p className="font-synonym_regular mt-2">We currently offer three main themes for our space themed kids birthday parties:<br />
                                            <span className="font-synonym_medium text-education-black">🚀 Rocketry</span>: we will build a rocket together, talk about rockets, watch videos of rockets, EVERYTHING about rockets<br />
                                            <span className="font-synonym_medium text-education-black">🪐 Astronomy</span>: we will build a model of the solar system, we will talk about planets, universe, moons etc. <br />
                                            <span className="font-synonym_medium text-education-black">👽 Aliens</span>: we will talk about the possibility of aliens, decode alien messages and even talk to aliens (pretend… for now)
                                        </p>
                                        <br />
                                        <span className="font-synonym_medium text-education-main">Unleash your child's inner astronaut at Louno Space!</span> Book your unforgettable space birthday party today!</p>
                                </div>
                                <div className="mt-24">
                                    <h2 className="text-3xl text-education-black">£135 <span className="text-xl">/ 10 kids</span><br /><span className="text-sm"> (+£10 / additional guest)</span></h2>
                                    <button onClick={openBirthdayModal} 
                                        className="w-full bg-education-pink-barbie p-4 mt-4 rounded-lg block text-education-white text-center uppercase">Plan now</button>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 mt-10">
                                <div>
                                    <ul>
                                        <li><span className="font-synonym_medium">Age</span>: 4-9 years old</li>
                                        <li><span className="font-synonym_medium">Trained Party Instructor</span>: Included</li>
                                        <li><span className="font-synonym_medium">Materials</span>: Included</li>
                                        <li><span className="font-synonym_medium">Interactive Experiment</span>: Included</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li><span className="font-synonym_medium">Party Invites</span>: Included</li>
                                        <li><span className="font-synonym_medium">Party Bags</span>: Included</li>
                                        <li><span className="font-synonym_medium">Theme of Choice</span>: Aliens, Rocketry or Astronomy</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li><span className="font-synonym_medium">Additional Guest (£10pp)</span>: Optional</li>
                                        <li><span className="font-synonym_medium">Extra Activity/Experiment</span>: Optional</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BirthdayModal isOpen={isBirthdayModalOpen} onClose={closeBirthdayModal} page="build-a-rocket" />

            <script src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/datepicker.min.js"></script>
            <Footer className="z-0" />
        </>
    )
};

export default BDay;
