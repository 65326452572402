import React, { useState } from 'react';
import FeedbackModal from '../utils/FeedbackModal';

const FeedbackButton = ({page}) => {
	const [showModal, setShowModal] = useState(false);

	const handleButtonClick = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	return (
		<div className="fixed bottom-0 z-10 left-20">
			<button
				className={`bg-education-pink-barbie text-white font-bold py-2 px-4 font-chillax_regular ${showModal ? 'hidden' : 'block'}`}
				onClick={handleButtonClick}
			>
				Feedback
			</button>

			{showModal && <FeedbackModal handleClose={handleCloseModal} page={page}/>}
		</div>
	);
};

export default FeedbackButton;
