import React from "react";
import { Helmet } from "react-helmet";

const HSTracker = () => {
    return (
        <Helmet>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/143438058.js"></script>
        </Helmet>
    );
};

export default HSTracker;
