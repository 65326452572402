import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loader from '../common/Loader';

const OurEducation = () => {
    const [loading, setLoading] = useState(false);

    const items = [
        { icon: 'Curriculum.png', title: 'Engaging Curriculum', text: 'Our carefully crafted primary schools education curriculum is designed to captivate young minds with STEM and space science. We weave together captivating stories, interactive lessons, and hands-on activities to make learning about space technology a thrilling adventure. By presenting complex concepts in accessible and exciting ways, we create a foundation for students to explore the universe\'s wonders with enthusiasm and eagerness.' },
        { icon: 'RoleModels.png', title: 'Inspiring Role Models', text: 'We believe in the power of human connection. That\'s why we connect students with inspiring role models from space engineering and exploration. Through virtual guest speakers, video interviews, and live Q& A sessions, children can hear firsthand from astronauts, scientists, engineers, and other professionals who have significantly contributed to space exploration. These interactions serve as powerful motivators, showing students that they, too, can aspire to reach new frontiers.' },
        { icon: 'Collaboration.png', title: 'Collaborative Learning', text: 'We foster a sense of community and collaboration among our students in our STEM education programs. Through online forums, group projects, and interactive discussions, children can exchange ideas, collaborate on exciting challenges, and learn from one another. Students develop teamwork skills, critical thinking abilities, and a broader perspective on space exploration by working together.' },
        { icon: 'Applications.png', title: 'Real-world Applications', text: 'We emphasise the practical applications of space science education. By showcasing how knowledge of space sciences and space technology can be applied in real-life scenarios, we inspire students to see the relevance and potential impact of their learning. Whether it\'s understanding the technology behind satellite communications or exploring the possibilities of space tourism, we help students see how their interests and skills can contribute to the future of space exploration.' },
        { icon: 'OngoingSupport.png', title: 'Ongoing Support', text: 'Our commitment to inspiring the next generation of space explorers goes beyond the classroom. We provide ongoing support and resources to students, including access to additional STEM and space education materials, learning opportunities, and mentorship programs. We aim to create a nurturing environment where students can continuously grow their passion for space and receive guidance as they pursue their dreams.' },
    ];

    const [selectedItem, setSelectedItem] = useState(items[0]);
    return (
        <>
            <div className="isolate bg-education-white">
                {loading && <Loader />}
                {!loading && <Header />}
                {
                    !loading &&
                    <>
                        <div className="bg-education-white pb-24" id="way">
                            <div className="flex flex-col max-w-screen-lg px-8 mx-auto z-50 h-auto md:pb-12 lg:py-10 md:items-center md:justify-between md:flex-row">
                                <div className="flex flex-col w-full justify-center mb-5 items-start text-center md:mb-0 lg:pt-0 lg:text-left font-synonym_regular">
                                    <h1 className="my-4 leading-tight text-education-main text-3xl sm:text-3xl md:text-4xl md:text-center lg:text-left md:mx-auto w-full font-chillax_medium">
                                        Our Way to Inspire
                                    </h1>
                                    <p className="sm:w-full sm:mx-0 md:w-2/3 md:mx-auto lg:mx-0">
                                        At Louno Space, we are dedicated to inspiring the next generation of space explorers through primary education. Our approach encompasses a range of strategies and initiatives designed to ignite curiosity, nurture a passion for space science and space engineering, and empower children to reach for the stars in the field of STEM education.
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col max-w-screen-lg px-8 z-50 h-auto md:flex-row justify-start mx-auto pt-8">
                                <div className="w-full md:w-1/2 p-4">
                                    {items.map((item, index) => (
                                        <div className="flex">
                                            <img src={`./assets/images/${item.icon}`} className="w-8 h-8 mr-4" />
                                            <h3
                                                key={index}
                                                className="cursor-pointer font-chillax_medium text-education-main text-xl mb-8 hover:ml-4 duration-300"
                                                onClick={() => setSelectedItem(item)}
                                            >
                                                {item.title}
                                            </h3>
                                        </div>
                                    ))}
                                </div>
                                <div className="w-full md:w-1/2 p-4 bg-white float-left font-synonym_regular text-gray-600 text-md shadow-xl rounded-lg mx-2">
                                    <div className="flex">
                                        <img src={`./assets/images/${selectedItem.icon}`} className="w-8 h-8 mr-4" />
                                        <h3
                                            className="cursor-pointer font-chillax_medium text-education-main text-xl mb-8 hover:ml-4 duration-300"
                                        >
                                            {selectedItem.title}
                                        </h3>
                                    </div>
                                    {selectedItem && <p>{selectedItem.text}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="bg-white" id="approach">
                            <div className="flex flex-col max-w-screen-lg px-8 mx-auto z-50 h-auto md:pb-12 lg:py-10 md:items-center md:justify-between md:flex-row">
                                <div className="flex flex-col w-full justify-center mb-5 items-start text-center md:mb-0 lg:pt-0 lg:text-left font-synonym_regular">
                                    <h1 className="my-4 leading-tight text-education-main text-3xl sm:text-3xl md:text-4xl md:text-center  md:mx-auto w-full font-chillax_medium">
                                        Our Approach
                                    </h1>
                                    <p className="w-full md:w-2/3 mx-auto text-center font-synonym_regular">
                                        We are passionate about providing primary school children with an exciting and engaging space education experience. Our approach revolves around combining the power of people and technologies to ensure our students learn in a fun and engaging way.
                                    </p>
                                </div>
                            </div>
                            <div className="max-w-screen-xl px-8 z-50 h-auto md:flex-row justify-start mx-auto pt-8 ">
                                <div class="md:flex md:grid-cols-3 md:pb-16 p-4">
                                    <div className="w-full md:w-1/3">
                                        <h3 className="font-chillax_medium text-xl pr-4 sm:mb-4 md:mb-0">
                                            Register Interest in STEM and Space Science
                                        </h3>
                                    </div>
                                    <div className="w-full md:w-2/3">
                                        <p className="font-synonym_regular text-gray-600 sm:text-left md:text-center sm:mb-4 md:mb-0">
                                            We create and launch a user-friendly survey that actively engages children in primary education, ensuring their participation and stimulating their interest in space technology and engineering, and STEM overall.
                                        </p>
                                    </div>
                                    <div className="w-full md:w-1/3">
                                        <p className="uppercase font-chillax_regular sm:text-left md:text-right">
                                            Pre-assessment
                                        </p>
                                    </div>
                                </div>
                                <div class="md:flex md:grid-cols-3 md:pb-16 p-4">
                                    <div className="w-full md:w-1/3">
                                        <h3 className="font-chillax_medium text-xl pr-4 sm:mb-4 md:mb-0">
                                            Louno Space Science Content
                                        </h3>
                                    </div>
                                    <div className="w-full md:w-2/3">
                                        <p className="font-synonym_regular text-gray-600 sm:text-left md:text-center sm:mb-4 md:mb-0">
                                            We then the pre-assessment results to create primary schools education content that is specifically tailored to children's interests, skills, and desired learning outcomes in STEM and space science.
                                        </p>
                                    </div>
                                    <div className="w-full md:w-1/3">
                                        <p className="uppercase font-chillax_regular sm:text-left md:text-right">
                                            Generating Content
                                        </p>
                                    </div>
                                </div>
                                <div class="md:flex md:grid-cols-3 md:pb-16 p-4">
                                    <div className="w-full md:w-1/3">
                                        <h3 className="font-chillax_medium text-xl pr-4 sm:mb-4 md:mb-0">
                                            Registration for Space Engineering Knowledge Delivery
                                        </h3>
                                    </div>
                                    <div className="w-full md:w-2/3">
                                        <p className="font-synonym_regular text-gray-600 sm:text-left md:text-center sm:mb-4 md:mb-0">
                                            We create and launch a user-friendly survey that actively engages children, ensuring their participation and stimulating their interest in space engineering and technology within the context of primary education.
                                        </p>
                                    </div>
                                    <div className="w-1/3">
                                        <p className="uppercase font-chillax_regular sm:text-left md:text-right">
                                            Pre-assessment
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                }
                {!loading && <Footer />}
            </div >
        </>
    )
};

export default OurEducation;