import React, { useRef, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const BirthdayModal = ({ isOpen, onClose, page }) => {
    const modalRef = useRef();
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isOpen, onClose]);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        message: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const formv3 = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/3d796370-6ef5-4d56-9441-7bb32f99efa1';

        // Example request JSON:
        var data = {
            submittedAt: Date.now(),
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: formData.email,
                },
                {
                    objectTypeId: "0-1",
                    name: "firstname",
                    value: formData.firstname,
                },
                {
                    objectTypeId: "0-1",
                    name: "lastname",
                    value: formData.lastname,
                },
                {
                    objectTypeId: "0-1",
                    name: "messagebday",
                    value: formData.messagebday,
                },
                {
                    objectTypeId: "0-1",
                    name: "child_name",
                    value: formData.child_name,
                },
                {
                    objectTypeId: "0-1",
                    name: "bday_date_t",
                    value: formData.bday_date_t,
                },
                {
                    objectTypeId: "0-1",
                    name: "number_of_kids",
                    value: formData.number_of_kids,
                },
                {
                    objectTypeId: "0-1",
                    name: "age_range_of_the_kids",
                    value: formData.age_range_of_the_kids,
                },
                {
                    objectTypeId: "0-1",
                    name: "location_party",
                    value: formData.location_party,
                },
                {
                    objectTypeId: "0-1",
                    name: "at_your_place",
                    value: formData.place_party,
                },
                {
                    objectTypeId: "0-1",
                    name: "page",
                    value: page,
                },
            ],
            context: {
                hutk: Cookies.get('hubspotutk'),
            },
        };


        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage("Message sent. We will reply within 48 hours. Thank you 🚀");
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    const handleChangeEmailAddress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // Submit the form or perform any desired action
            formv3();
        } else {
            setEmailAddress(event.target.value);
        }
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? 'visible' : 'hidden'}`}>
            <div className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
                <div className="popup-overlay"></div>
                <div className="popup-content">
                    <div className="flex items-center justify-center h-full w-full">
                        <div className="bg-white rounded-lg max-w-2xl">
                            <section className="overflow-hidden rounded-lg shadow-2xl md:grid md:grid-cols-1">
                                {/* Form Section */}
                                <div className="p-4 text-center sm:p-6 md:col-span-2 lg:p-8">
                                    <h2 className="mt-6 font-black uppercase">
                                        <span className="text-4xl font-black sm:text-5xl lg:text-6xl">PLAN NOW</span>
                                        <span className="mt-2 block text-sm">Tell us more about your birthday party</span>
                                    </h2>
                                    <form className="w-full mt-16" onSubmit={formv3}>
                                        <div className="grid grid-cols-3 gap-x-10">
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="firstname" className="block text-gray-700 text-sm font-medium mb-2">
                                                        First Name *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="firstname"
                                                        name="firstname"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.firstname}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="lastname" className="block text-gray-700 text-sm font-medium mb-2">
                                                        Last Name *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="lastname"
                                                        name="lastname"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.lastname}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">
                                                        Email Address *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="email"
                                                        name="email"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.email}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="child_name" className="block text-gray-700 text-sm font-medium mb-2">
                                                        Your child's name *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="child_name"
                                                        name="child_name"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.child_name}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="bday_date_t" className="block text-gray-700 text-sm font-medium mb-2">
                                                        Birthday date *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="bday_date_t"
                                                        name="bday_date_t"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.bday_date_t}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="number_of_kids" className="block text-gray-700 text-sm font-medium mb-2">
                                                        Additional kids *
                                                        <p className="text-gray-400 text-[12px]">£10 per additional guest</p>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="number_of_kids"
                                                        name="number_of_kids"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.number_of_kids}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="age_range_of_the_kids" className="block text-gray-700 text-sm font-medium mb-2">
                                                        Age range of the kids *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="age_range_of_the_kids"
                                                        name="age_range_of_the_kids"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.age_range_of_the_kids}
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="place_party" className="block text-gray-700 text-sm font-medium mb-2">
                                                        Where would you like to host the workshop? *
                                                        <p className="text-gray-400 text-[12px]">(e.g. your place or school)</p>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="place_party"
                                                        name="place_party"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.place_party}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label htmlFor="location_party" className="block text-gray-700 text-sm font-medium mb-2">
                                                        Where are you based? *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="location_party"
                                                        name="location_party"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.location_party}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-2">
                                                <div className="mb-4">
                                                    <label htmlFor="messagebday" className="block text-gray-700 text-sm font-medium mb-2">
                                                        More details *
                                                    </label>
                                                    <textarea
                                                        id="messagebday"
                                                        name="messagebday"
                                                        rows="4"
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-education-main focus:border-education-main block w-full p-2.5"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={formData.messagebday}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="w-full h-auto mt-8 bg-education-main py-4 text-sm font-bold uppercase tracking-widest text-white rounded-md"
                                                >
                                                    Send request
                                                </button>

                                                <span className="text-xs">
                                                    By submitting the form you agree to our <a href="policies">Terms and Conditions.</a>
                                                </span>

                                                <br />
                                                <span className="text-education-ming">{message}</span>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </section>
                            <button
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                                onClick={handleCloseModal}
                            >
                                X
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BirthdayModal;
