import React, { useState, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import AOS from 'aos';
import "aos/dist/aos.css";
import Cookies from 'js-cookie';
import { MdOutlineScience } from "react-icons/md";
import { useSpring, animated } from 'react-spring';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Iframe from 'react-iframe'
import ModalNotifyWorkshop from '../../utils/ModalNotifyWorkshop'
import ModalWaitinglist from '../../utils/ModalWaitingList'
import TrustBoxContainer from '../../utils/Trustpilot';
import Footer from '../common/Footer';
import Header from '../common/Header';

const Webinar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenWidth();

        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobile(prevState => !prevState);
    };

    const [isNavOpen, setIsNavOpen] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/f895927d-67e1-4488-8fdc-a56fd255d8aa';

        // Example request JSON:
        var data = {
            submittedAt: Date.now(),
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: formData.email,
                }
            ],
            context: {
                hutk: Cookies.get('hubspotutk'),
            },
        };

        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage("You're in! You will receive an email with more details soon. Thank you 🚀");
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    return (
        <>
            <div className="bg-white overflow-y-hidden">
                <Header />
                <div class="relative isolate overflow-hidden bg-white py-12 sm:py-24 mx-auto max-w-7xl" id="about">
                    <div class="px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2">
                        <div className="mr-0 md:mr-4">
                            <div class="mx-auto max-w-2xl lg:mx-0 col-span-1 w-full">
                                <h1 class="text-4xl font-bold tracking-tight text-education-space sm:text-5xl font-chillax_medium">Join our <span className="text-education-pink-barbie">FREE</span> webinar</h1>
                                <p class="font-synonym_regular mt-6 text-lg leading-8 text-gray-900 text-justify">
                                    <span className="font-synonym_medium text-education-main">Is your child a curious explorer with a twinkle in their eye and a thirst for adventure?</span>  Do you dream of nurturing their love for learning and setting them on a path to endless possibilities? ✨
                                    <br /><br />
                                    Then join us for a <span className="font-synonym_medium text-education-main">FREE interactive webinar</span> where we'll blast off on a journey to ignite your child's passion for (Science, Technology, Engineering, Maths) STEM and space!
                                    <br /><br />
                                    We will discuss why STEM and Space are important for the future of our society and how we can keep children inspired. We will also give you the opportunity to ask questions and to have a glimpse at what we do during our workshops!
                                    <br /><br />
                                    <span className="text-education-main">This workshop is aimed at parents of children aged 4-9 who want their children to explore, learn, and be inspired about STEM!</span>
                                </p>
                                <br /><br />
                                <a href="#register" className="p-4 text-education-white bg-education-pink-barbie rounded-lg text-xl w-full mx-auto font-synonym_medium">Register now!</a>
                                <br /><br /><br />
                                {/* <div class="grid grid-cols-2 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-education-space md:grid-cols-2 md:flex lg:gap-x-10 mt-8 bottom-0 align">
                                    <a href="/" className="hover:text-education-main duration-200">Next workshops <span aria-hidden="true">&rarr;</span></a>
                                    <a href="/" className="hover:text-education-main duration-200">FAQs <span aria-hidden="true">&rarr;</span></a>
                                    <a href="/" className="hover:text-education-main duration-200">About us <span aria-hidden="true">&rarr;</span></a>
                                </div> */}
                            </div>
                        </div>
                        <div className="ml-0 md:ml-8">
                            <p class="font-synonym_regular mt-6 text-lg leading-8 text-gray-900 text-justify">
                                <img src="/assets/images/Louno_Space_Webinar.png" className="rounded-3xl" />
                            </p>
                        </div>
                    </div>

                    <section class="bg-gray-50 my-16 rounded-3xl" id="register">
                        <div class="p-8 md:p-12 lg:px-8 lg:py-24">
                            <div class="mx-auto max-w-2xl text-center">
                                <h2 class="text-2xl font-bold text-gray-900 md:text-3xl font-chillax_medium">
                                    Whether you're a STEM professional yourself or simply want to give your child a head start, this webinar is for you!
                                </h2>

                                {/* <p class="hidden text-gray-500 sm:mt-4 sm:block">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae dolor officia blanditiis
                                repellat in, vero, aperiam porro ipsum laboriosam consequuntur exercitationem incidunt
                                tempora nisi?
                            </p> */}
                            </div>

                            <div class="mx-auto mt-8 max-w-xl">
                                <form class="sm:flex sm:gap-4" onSubmit={handleSubmit}>

                                    <div class="sm:flex-1">
                                        <label for="email" class="sr-only">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Email address"
                                            class="w-full rounded-lg border-gray-200 bg-white p-3 text-gray-700 shadow-sm transition focus:border-white focus:outline-none focus:ring focus:ring-yellow-400 "
                                        />
                                    </div>

                                    <button
                                        type="submit"
                                        class="group mt-4 flex w-full items-center justify-center gap-2 bg-education-pink-barbie rounded-lg px-5 py-3 text-white transition focus:outline-none focus:ring focus:ring-yellow-400 sm:mt-0 sm:w-auto"
                                    >
                                        <span class="text-sm font-medium">Register now</span>

                                        <svg
                                            class="h-5 w-5 rtl:rotate-180"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                                            />
                                        </svg>
                                    </button>
                                </form>
                                <span className="text-education-ming">{message}</span>
                            </div>
                        </div>
                    </section>

                    <div className="grid grid-cols-1 md:grid-cols-2 pt-4 gap-4 md:gap-16">
                        <div className="px-4 mx-4 md:px-0 md:mx-0">
                            <p class="font-synonym_regular mt-6 text-lg leading-8 text-gray-900 text-justify">
                                <img src="/assets/images/lounospace_webinar2.jpg" className="rounded-3xl" />
                            </p>
                        </div>
                        <div class="lg:mx-0 col-span-1 mx-8 md:mx-auto mr-0 md:mr-8 w-3/4 md:w-full">
                            <div class="mx-auto max-w-2xl lg:mx-0 col-span-1 w-full">
                                <p class="font-synonym_regular mt-6 text-lg leading-8 text-gray-900 text-justify">
                                    <span className="font-synonym_medium text-education-main">Don't miss this chance to:</span>
                                    <ul class="md:space-y-4 text-gray-500 list-disc list-inside">
                                        <li>Unleash your child's curiosity and potential.</li>
                                        <li>Fuel a lifelong love of learning and exploration.</li>
                                        <li>Connect with other STEM-loving families.</li>
                                        <li>Get exclusive access to early bird discounts for our workshops!</li>
                                        <li>Register for the webinar today and take the first step on your child's incredible STEM journey!</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 pt-4 gap-4 md:gap-16">
                        <div className="px-4 mx-4 md:px-0 md:mx-0">
                            <section class="bg-gray-50 md:my-16 md:py-16 rounded-3xl">
                                <div class="p-8 lg:px-8 lg:py-4">
                                    <div class="mx-auto max-w-2xl text-center">
                                        <h2 class="text-2xl font-bold text-gray-900 md:text-3xl font-chillax_medium">
                                            <span className="text-xl">Join us on</span>
                                            <br />21st February 2024<br /><span className="text-lg"> at 7:00-8:00 pm</span>
                                            {/* <br /><span className="text-xl">or</span><br />
                                            6th February 2024 <span className="text-lg"> 7:00-8:00 pm</span> */}
                                        </h2>

                                        <p class="hidden text-gray-500 sm:mt-4 sm:block">
                                            We will be live on Facebook - you will receive further<br />details upon registration.
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="lg:mx-0 col-span-1 mx-8 md:mx-auto mr-0 md:mr-8 w-3/4 md:w-full">
                            <p class="font-synonym_regular text-lg leading-8 text-gray-900 text-justify mt-2 md:mt-16">
                                <span className="font-synonym_medium text-education-main">Agenda as follows:</span>
                                <ul class="space-y-4 text-gray-500 list-disc list-inside">
                                    <li>Welcome and Introduction</li>
                                    <li>Why STEM and Space Matter: Benefits for Young Minds </li>
                                    <li>Interactive Activity: Spark Curiosity at Home </li>
                                    <li>Reach for the Stars: The Power of Space Exploration </li>
                                    <li>Family Fun Ideas: STEM Activities from Outdoors to Videos </li>
                                    <li>Workshop Sneak Peek: Launching your Child's STEM Adventure</li>
                                    <li>Q&A Session </li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 pt-4 gap-4 md:gap-16">
                        <div className="px-4 mx-4 md:px-0 md:mx-0">
                            <section class="bg-gray-50 md:my-16 md:py-16 rounded-3xl">
                                <div class="p-8 lg:px-8 lg:py-4">
                                    <div class="mx-auto max-w-2xl text-center">
                                        <h2 class="text-2xl font-bold text-gray-900 md:text-3xl font-chillax_medium">
                                            Register now, it's FREE!
                                        </h2>

                                        <p class="hidden text-gray-500 sm:mt-4 sm:block">
                                            Don't let this opportunity to launch your child's love for STEM and space fly by! Register now and join us for an unforgettable adventure!
                                        </p>
                                    </div>

                                    <div class="mx-auto mt-8 max-w-xl">
                                        <form class="sm:flex sm:gap-4" onSubmit={handleSubmit}>
                                            <div class="sm:flex-1">
                                                <label for="email" class="sr-only">Email</label>

                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Email address"
                                                    class="w-full rounded-lg border-gray-200 bg-white p-3 text-gray-700 shadow-sm transition focus:border-white focus:outline-none focus:ring focus:ring-yellow-400 "
                                                />
                                            </div>

                                            <button
                                                type="submit"
                                                class="group mt-4 flex w-full items-center justify-center gap-2 bg-education-pink-barbie rounded-lg px-5 py-3 text-white transition focus:outline-none focus:ring focus:ring-yellow-400 sm:mt-0 sm:w-auto"
                                            >
                                                <span class="text-sm font-medium">Register now</span>

                                                <svg
                                                    class="h-5 w-5 rtl:rotate-180"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                                                    />
                                                </svg>
                                            </button>
                                        </form>
                                        <span className="text-education-ming">{message}</span>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="lg:mx-0 col-span-1 mx-8 md:mx-auto mr-0 md:mr-8 w-3/4 md:w-full">
                            <p class="font-synonym_regular text-lg leading-8 text-gray-900 text-justify mt-2 md:mt-16">
                                <span className="font-synonym_medium text-education-main">By registering, you'll also receive:</span>
                                <ul class="space-y-4 text-gray-500 list-disc list-inside">
                                    <li>A bonus downloadable STEM activity pack!</li>
                                    <li>Regular updates on our latest workshops and resources.</li>
                                    <li>Invitations to exclusive events and community activities.</li>
                                    <li>An excluside discount code for your next session.</li>
                                    <li>The chance to meet and ask questions to a real space engineer.</li>
                                    <li>An opportunity for your child to learn and be inspired.</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div class="px-6">
                        <div className="mr-0 md:mr-0 grid grid-cols-1 mt-16 md:mt-0 md:grid-cols-4">
                            <div className="col-span-2">
                                <h3 className="font-chillax_medium text-4xl">The Speaker: Mirko Viviano</h3>
                                <p class="font-synonym_regular mt-6 text-lg leading-8 text-gray-900 text-justify">
                                    <img src="/assets/images/MirkoViviano_LounoSpace_Webinar.png" className="rounded-3xl w-[80%]" />
                                </p>
                            </div>
                            <div class="mx-auto lg:mx-0 col-span-2 w-full">
                                <p class="font-synonym_regular text-lg leading-8 text-gray-900 text-justify mt-16">
                                    <span className="font-synonym_medium text-education-main">Hi, I am Mirko, and I am the founder of Louno Space.</span>
                                    <br />
                                    I have 8 years of combined experience in the space and education industry. As a Space Engineer, I translate cosmic ambition into practical solutions, whether it's designing spacecraft systems, crunching mission data, or brainstorming lunar infrastructure like smart robotic explorers.
                                    <br /><br />
                                    Beyond engineering, I'm an entrepreneur and educator. As founder and CEO of Louno Space, I ignite young minds with the wonders of STEM and space careers. A Payload 30 Under 30 and STEM Ambassador, I'm passionate about advancing the space industry.
                                    <br /><br />
                                    <span className="font-synonym_medium text-education-main">Join me in inspiring the next generations of space explorers 🚀</span>
                                </p>
                                <br /><br />
                                <div className="flex">
                                    <a href="https://mirkoviviano.it?utm_source=lounospace" className="p-4 text-education-white bg-education-main rounded-lg text-xl mr-8 font-synonym_medium">Meet Mirko</a>
                                    <a href="#register" className="p-4 text-education-white bg-education-pink-barbie rounded-lg text-xl font-synonym_medium">Register now!</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
            <Footer />
        </>
    );
}

export default Webinar;

