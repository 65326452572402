import React, { useState, useEffect } from 'react';
import Carousel from '../../utils/Carousel'

const Trustpilot = () => {
    return (
        <>
            <Carousel />
        </>
    )
}

export default Trustpilot;
