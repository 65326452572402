import React from 'react';

const Loader = () => {
  return (
    <div className="flex h-screen">
        <div className="m-auto">
            <img src="/assets/images/loader-y.gif" width="150" alt="Louno Space"/>
        </div>
    </div>
  );
}

export default Loader;