import React, { useRef, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const ContactUsModal = ({ isOpen, onClose, page }) => {
    const modalRef = useRef();
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isOpen, onClose]);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        message: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const formv3 = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/c66f4f28-c5f0-45d4-a256-ecf3d7c8da28';

        // Example request JSON:
        var data = {
            submittedAt: Date.now(),
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: formData.email,
                },
                {
                    objectTypeId: "0-1",
                    name: "firstname",
                    value: formData.firstname,
                },
                {
                    objectTypeId: "0-1",
                    name: "lastname",
                    value: formData.lastname,
                },
                {
                    objectTypeId: "0-1",
                    name: "message",
                    value: formData.message,
                },
                {
                    objectTypeId: "0-1",
                    name: "page",
                    value: page,
                },
            ],
            context: {
                hutk: Cookies.get('hubspotutk'),
            },
        };


        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage("Message sent. We will reply within 48 hours. Thank you 🚀");
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    const handleChangeEmailAddress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // Submit the form or perform any desired action
            formv3();
        } else {
            setEmailAddress(event.target.value);
        }
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? 'visible' : 'hidden'}`}>
            <div className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
                <div className="popup-overlay"></div>
                <div className="popup-content">
                    <div className="flex items-center justify-center h-full w-full">
                        <div className="bg-white rounded-lg max-w-2xl">
                            <section className="overflow-hidden rounded-lg shadow-2xl md:grid md:grid-cols-1">
                                {/* Form Section */}
                                <div className="p-4 text-center sm:p-6 md:col-span-2 lg:p-8">
                                    <h2 className="mt-6 font-black uppercase">
                                        <span className="text-4xl font-black sm:text-5xl lg:text-6xl">ENQUIRE</span>
                                        <span className="mt-2 block text-sm">we're here to answer all your questions</span>
                                    </h2>
                                    <form className="w-full max-w-sm mt-16" onSubmit={formv3}>
                                        <div className="mb-4">
                                            <label htmlFor="firstname" className="block text-gray-700 text-sm font-medium mb-2">
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                id="firstname"
                                                name="firstname"
                                                className="appearance-none bg-transparent border-b border-education-main w-full text-gray-700 leading-tight focus:outline-none"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.firstname}
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="lastname" className="block text-gray-700 text-sm font-medium mb-2">
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                id="lastname"
                                                name="lastname"
                                                className="appearance-none bg-transparent border-b border-education-main w-full text-gray-700 leading-tight focus:outline-none"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.lastname}
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">
                                                Email Address
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="appearance-none bg-transparent border-b border-education-main w-full text-gray-700 leading-tight focus:outline-none"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.email}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <label htmlFor="message" className="block text-gray-700 text-sm font-medium mb-2">
                                                Message
                                            </label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                rows="4"
                                                className="appearance-none bg-transparent border-b border-education-main w-full text-gray-700 leading-tight focus:outline-none"
                                                required
                                                onChange={handleInputChange}
                                                value={formData.message}
                                            ></textarea>
                                        </div>

                                        <button
                                            type="submit"
                                            className="w-full bg-black py-4 text-sm font-bold uppercase tracking-widest text-white"
                                        >
                                            Send message
                                        </button>

                                        <span className="text-xs">
                                            By submitting the form you agree to our <a href="policies">Terms and Conditions.</a>
                                        </span>

                                        <br />
                                        <span className="text-education-ming">{message}</span>
                                    </form>

                                </div>
                            </section>
                            <button
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                                onClick={handleCloseModal}
                            >
                                X
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsModal;
