import React, { useState, useEffect, useParams } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../common/Footer';

const ThankYou = () => {

    const [activeTab, setActiveTab] = useState('outcome');
    const [isModalOpen, setModalOpen] = useState(false);
    const [faqView, setfaqView] = useState('faq1');

    const [modalData, setModalData] = useState({
        isOpen: false,
        dateWorkshop: '', // Add any other necessary modal data
    });

    const openModal = (date) => {
        setModalData({
            isOpen: true,
            dateWorkshop: date,
            // Set other modal data as needed
        });
    };

    const [isContactUsModalOpen, setContactUsModalOpen] = useState(false);

    const openContactUsModal = () => {
        setContactUsModalOpen(true);
    };

    const closeContactUsModal = () => {
        setContactUsModalOpen(false);
    };
    const closeModal = () => {
        setModalData({
            isOpen: false,
            dateWorkshop: '',
            // Set other modal data as needed
        });
    };

    const handleFAQClick = (faq) => {
        setfaqView(faq);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        AOS.init();
    }, [])

    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenWidth();

        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobile(prevState => !prevState);
    };

    return (
        <>
            <div class="relative isolate overflow-hidden bg-education-white">
                <div className="bg-education-pink-barbie">
                    <div className="p-4 text-education-white text-center mx-auto">
                        <p className="font-synonym_medium">Bring a sibling or a friend for a 10% discount! Use code 'MOONLANDING' at checkout 🚀</p>
                    </div>
                </div>
                <div className="bg-education-white px-0 py-4 text-education-black items-center mx-auto max-w-7xl">
                    <div className="px-6 lg:px-8 flex justify-between items-center">
                        <div className="flex items-center">
                            <a href="/">
                                <img src="/assets/images/logo-short.png" className="w-48" alt="Logo" />
                            </a>
                        </div>
                        {isMobile ? (
                            <button className="lg:hidden focus:outline-none" onClick={() => setIsNavOpen((prev) => !prev)}>
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                                </svg>
                            </button>
                        ) : (
                            <div className="flex gap-x-12 items-center">
                                <a href="/" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Home
                                </a>
                                <a href="/" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    About us
                                </a>
                                <a href="/" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Workshops
                                </a>
                                <a href="/" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    FAQs
                                </a>
                                <a href="/" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                                    Contact us
                                </a>
                                <a href="#sessions" className="bg-education-main p-2 rounded-lg text-education-white">
                                    Book now
                                </a>
                            </div>
                        )}
                    </div>
                    {isMobile && (
                        <div className={`lg:hidden ${isMobile && isNavOpen ? 'block' : 'hidden'}`}>
                            {/* Mobile menu content goes here */}
                            <div className="px-6 py-8 bg-white text-center">
                                <a href="/" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                                    Home
                                </a>
                                <a href="/" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                                    About us
                                </a>
                                <a href="/" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                                    Workshops
                                </a>
                                <a href="/" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                                    FAQs
                                </a>
                                <a href="/" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                                    Contact us
                                </a>
                                <a href="#sessions" className="block py-2 bg-education-main text-education-white rounded-lg mt-4 font-chillax_medium">
                                    Book now
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ThankYou;
