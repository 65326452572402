import React, { useRef, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const ModalNotifyWorkshop = ({ isOpen, onClose, workshopDate, workshop }) => {
    const modalRef = useRef();
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isOpen, onClose]);

    const formv3 = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/8a14e226-57c0-40ef-bafb-575cdd895a5f';

        // Example request JSON:
        var data = {
            "submittedAt": Date.now(),
            "fields": [
                {
                    "objectTypeId": "0-1",
                    "name": "email",
                    "value": emailAddress
                },
                {
                    "objectTypeId": "0-1",
                    "name": "notifyme_workshop_date",
                    "value": workshopDate
                },
                {
                    "objectTypeId": "0-1",
                    "name": "notifyme_workshop_name",
                    "value": workshop
                }
            ],
            "context": {
                "hutk": Cookies.get('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
            }
        };

        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage("Done. You will receive an email when a spot will free. Thank you 🚀");
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    const handleChangeEmailAddress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // Submit the form or perform any desired action
            formv3();
        } else {
            setEmailAddress(event.target.value);
        }
    };

    const handleCloseModal = () => {
        onClose();
    };


    return (
        <div className={`z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? 'visible' : 'hidden'}`}>
            <div className="fixed inset-0 overflow-hidden flex items-center justify-center">
                <div className="popup-overlay"></div>
                <div className="popup-content">
                    <div className="flex items-center justify-center h-full w-full">
                        <div className="bg-white rounded-lg max-w-2xl">
                            <section className="overflow-hidden rounded-lg shadow-2xl md:grid md:grid-cols-1">
                                <div className="p-4 text-center sm:p-6 md:col-span-1 lg:p-8">
                                    <h2 className="mt-6 font-black uppercase">
                                        <span className="text-4xl font-black sm:text-5xl lg:text-6xl">NOTIFY ME</span>
                                        <span className="mt-2 block text-sm">On your next workshop session</span>
                                    </h2>
                                    <form className="w-full max-w-sm mt-16" onSubmit={formv3}>
                                        <div className="flex items-center border-b border-education-main">
                                            <input
                                                className="appearance-none bg-transparent border-none w-full text-gray-700 leading-tight focus:outline-none"
                                                type="email"
                                                placeholder="Your best email..."
                                                required
                                                aria-label="Email Address"
                                                onChange={handleChangeEmailAddress}
                                                value={emailAddress}
                                            />
                                            <button
                                                className="inline-block w-full bg-black py-4 text-sm font-bold uppercase tracking-widest text-white"
                                                type="button"
                                                onClick={formv3}
                                            >
                                                Notify me
                                            </button>
                                        </div>
                                        <span className="text-xs">By submitting the form you agree to our <a href="policies">Terms and Conditions.</a></span>
                                        <br />
                                        <span className="text-education-ming">{message}</span>
                                    </form>
                                </div>
                            </section>
                            <button
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                                onClick={handleCloseModal}
                            >
                                X
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalNotifyWorkshop;
