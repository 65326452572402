import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import AOS from 'aos';
import "aos/dist/aos.css";
import Cookies from 'js-cookie';
import { MdOutlineScience } from "react-icons/md";
import { useSpring, animated } from 'react-spring';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Iframe from 'react-iframe'
import ModalNotifyWorkshop from '../../utils/ModalNotifyWorkshop'
import ModalWaitinglist from '../../utils/ModalWaitingList'
import ModalEnquireNow from '../../utils/ModalEnquireNow'
import TrustBoxContainer from '../../utils/Trustpilot';
import Trustpilot from './Trustpilot';
import Header from '../common/Header'
import HeadTags from "../common/HeadTags";
import SessionBlock from './SessionBlock';
import VideoPopup from '../../utils/VideoPopup';
import FeedbackButton from '../../utils/FeedbackButton'

// import BannerSVG from '../common/BannerSvg'
// None	width: 100%;
// sm (640px)	max-width: 640px;
// md (768px)	max-width: 768px;
// lg (1024px)	max-width: 1024px;
// xl (1280px)	max-width: 1280px;
// 2xl (1536px)	max-width: 1536px;

const entries = [
    { name: 'Sarah, mum of David (aged 6)', quote: '“I would like my daughter to regain her interest in Maths.”', pic: "https://avatar.iran.liara.run/public/66.jpg" },
    { name: 'Mike, dad of Lizzy (aged 8)', quote: '“My son wants to build rockets all the time, and I want to support him.”', pic: "https://avatar.iran.liara.run/public/2.jpg" },
    { name: 'Bella, mum of Harry (aged 5)', quote: '“I am looking for a place I can take my kids and learn together.”', pic: "https://avatar.iran.liara.run/public/90.jpg" },
    { name: 'Alessandro, dad of Andrew (aged 7)', quote: '“I want my son to follow his dreams of becoming an astronomer.”', pic: "https://avatar.iran.liara.run/public/45.jpg" },
    { name: 'Nick, dad of Hannah (aged 6)', quote: '“My daughter is interested in arts and STEM, I am looking for something to combine them.”', pic: "https://avatar.iran.liara.run/public/42.jpg" },
    { name: 'Faaiza, mum of Zakir (aged 8)', quote: '“Being taught by a real space engineer is a whole different story”', pic: "https://avatar.iran.liara.run/public/95.jpg" },
    // Add more entries as needed
];

const getRandomEntry = () => entries[Math.floor(Math.random() * entries.length)];

const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' },
]

const Banner3 = () => {
    const [activeTab, setActiveTab] = useState('general');
    const [activeTabLocation, setActiveTabLocation] = useState('workshops');
    const [faqView, setfaqView] = useState('g1f1');
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const page = "home"

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        AOS.init();
    }, [])
    const [message, setMessage] = useState('');

    const [modalData, setModalData] = useState({
        isOpen: false,
        dateWorkshop: null,
        workshop: null,
        // Add other modal data as needed
    });

    const [enquireNowModalOpen, setEnquireNowModalOpen] = useState(false);
    const [waitingListModalOpen, setWaitingListModalOpen] = useState(false);

    // ... other component code

    const openWaitingListModal = (date, workshop) => {
        setWaitingListModalOpen(true);
        setModalData({
            isOpen: true,
            dateWorkshop: date,
            workshop: workshop,
            // Set other modal data as needed
        });
    };

    const openEnquireNowModal = () => {
        setEnquireNowModalOpen(true);
        setModalData({
            isOpen: true,
        });
    };

    const openModalWait = (date, workshop) => {
        setModalData({
            isOpen: true,
            dateWorkshop: date,
            workshop: workshop
            // Set other modal data as needed
        });
    };

    const closeModal = () => {
        setModalData({
            isOpen: false,
            dateWorkshop: '',
            workshop: ''
            // Set other modal data as needed
        });
        setMessage('')
    };

    const closeModalEnquireNow = () => {
        setModalData({
            isOpen: false,
        });
        setMessage('');
    };

    const closeModalWait = () => {
        setModalData({
            isOpen: false,
            dateWorkshop: '',
            workshop: ''
            // Set other modal data as needed
        });
        setMessage('')
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenWidth();

        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobile(prevState => !prevState);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/c66f4f28-c5f0-45d4-a256-ecf3d7c8da28';

        // Example request JSON:
        var data = {
            submittedAt: Date.now(),
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: formData.email,
                },
                {
                    objectTypeId: "0-1",
                    name: "firstname",
                    value: formData.firstName,
                },
                {
                    objectTypeId: "0-1",
                    name: "lastname",
                    value: formData.lastName,
                },
                {
                    objectTypeId: "0-1",
                    name: "message",
                    value: formData.message,
                },
                {
                    objectTypeId: "0-1",
                    name: "page",
                    value: "homepage",
                },
            ],
            context: {
                hutk: Cookies.get('hubspotutk'),
            },
        };

        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage("Message sent. We will reply within 48 hours. Thank you 🚀");
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleLocationTabClick = (tab) => {
        setActiveTabLocation(tab);
    };

    const handleFAQClick = (faq) => {
        setfaqView(faq);
    };

    const [isNavOpen, setIsNavOpen] = useState(false);

    const location = useLocation();

    useEffect(() => {
        // Check if there is an anchor in the URL
        const anchor = location.hash;

        if (anchor) {
            // Scroll to the element with the specified ID (remove the '#' character)
            const targetElement = document.getElementById(anchor.substring(1));

            if (targetElement) {
                // Smooth scroll to the target element
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);

    const openModal = (date) => {
        setModalData({
            isOpen: true,
            dateWorkshop: date,
            // Set other modal data as needed
        });
    };

    const openPopupVideo = () => {
        setModalData({
            isOpenVideo: true
        });
    };

    const openPopup = () => {
        setIsOpenVideo(true);
    };

    const closePopup = () => {
        setIsOpenVideo(false);
    };

    return (
        <>
            <HeadTags pageTitle="STEM Education for Kids - London" />
            <Header />
            <FeedbackButton page="home" />
            <div className="z-0 bg-gradient-to-r from-[#ff4b14] to-[#ffb143] bg-gradient-72 bg-cover bg-bottom bg-no-repeat h-[500px] relative font-synonym_medium">
                <div className="bg-kidshome-s xl:bg-kidshome h-full mx-auto bg-cover">
                    <div className="grid grid-cols-1 h-full p-4 md:p-8 pt-0 mt-0">
                        <div className="flex items-center justify-center p-4 md:p-8 text-white w-full md:w-3/4">
                            <div>
                                <h1 className="text-3xl md:text-5xl font-chillax_semibold -mt-48 md:mt-0">Make science <span className="text-education-black">fun</span><br />for your little ones.</h1>
                                <p className="pt-4 pb-8 w-full md:w-[55%]">Spark your child's curiosity about the universe and empower them to dream big with our engaging STEM workshops (for kids <span className="text-education-black">aged 4-11</span>).</p>
                                <a href="#sessions" className="p-4 text-education-black bg-education-white rounded-lg text-md font-synonym_medium">Explore workshops!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bg-kidshome bg-cover bg-center h-[500px]">

            </div> */}
            <div class="relative isolate overflow-hidden bg-white pt-16 md:pt-32 mx-auto max-w-7xl" id="about">
                <div class="px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2">
                    <div className="mr-0 md:mr-4 text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-education-space sm:text-5xl font-chillax_medium">About Us</h1>
                        <p className="font-synonym_variable mt-4 px-10 pb-12">
                            Here at Louno Space, we believe the seeds of curiosity and wonder are sown early. That's why we are passionate about igniting a lifelong love of science, technology, engineering, and maths (STEM) in young minds. Inspired by the boundless potential of space exploration, "Louno" (deriving from the word "Luna", "moon" in Italian) reflects our mission to take children on a captivating journey of discovery.
                            <br /><br />
                            We understand the importance of fostering a love of learning in a way that's accessible, engaging, and tailored specifically for children aged 4-9. Through our dynamic workshops, your child will embark on exciting adventures that spark their curiosity about the universe, encourage them to collaborate with their peers, and empower them to dream big!
                        </p>
                        <a href="/space-to-inspire" className="p-4 text-education-white bg-education-space rounded-lg text-md font-synonym_medium">Discover how we use Space To Inspire</a>
                    </div>
                    <div>
                        <div class="grid place-items-center p-4 shadow-lg bg-education-ming mt-16">
                            <div class="relative md:w-[640px] md:h-[360px] bg-white overflow-hidden">
                                <div aria-hidden="true" class="hidden md:grid absolute -top-10 -left-10 md:-top-16 md:-left-12 w-[120px] h-[120px] md:w-[200px] md:h-[200px] bg-education-main/70 rounded-full place-items-center">
                                    <svg class="w-[32px] h-[64px] md:w-[64px] md:h-[72px] ml-3 mt-3" viewBox="0 0 64 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M60 29.0718C65.3333 32.151 65.3333 39.849 60 42.9282L12 70.641C6.66668 73.7202 1.16801e-05 69.8712 1.19493e-05 63.7128L1.4372e-05 8.28719C1.46412e-05 2.12878 6.66668 -1.72022 12 1.35899L60 29.0718Z" fill="white" />
                                    </svg>
                                </div>
                                <div aria-hidden="true" class="absolute md:w-[512px] h-[512px] bg-education-main rounded-full -top-20 -right-56"></div>
                                <div class="absolute top-10 right-8">
                                    <img src="assets/images/louno_space_workshop_2.webp" alt="YouTuber cheerfully giving a shaka" className="hidden md:block w-64 h-36 object-cover rounded-2xl shadow-2xl rotate-3" />
                                    <img src="assets/images/louno_space_workshop_1.webp" alt="Screenshot of Tailwind CSS code" className="hidden md:block w-64 h-36 object-cover rounded-2xl shadow-2xl -rotate-3 translate-x-16 -translate-y-2" />

                                    <img src="assets/images/louno_space_workshop_2.webp" alt="YouTuber cheerfully giving a shaka" className="block md:hidden w-48 h-28 object-cover rounded-2xl shadow-2xl rotate-3 relative left-20" />

                                </div>
                                <div class="absolute right-12 inset-y-0 grid items-center">
                                    <button onClick={openPopup} className="w-12 h-12 bg-education-main rounded-full ring-4 ring-white grid place-items-center hover:bg-education-main/60 transition">
                                        <span className="sr-only">Watch the video</span>
                                        <svg className="ml-1 w-4" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z" fill="white" />
                                        </svg>
                                    </button>

                                </div>
                                <div class="relative p-8 flex flex-col justify-end h-full space-y-4 w-2/3">
                                    <p class="uppercase font-semibold text-xs text-education-main/80 tracking-wider">USE SPACE TO INSPIRE</p>
                                    <h1 class="text-xl md:text-4xl font-extrabold text-gray-900 font-chillax_medium">Unleash your child's inner astronaut</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="px-6 lg:px-8 grid grid-cols-1 md:grid-cols-1">
                    {/* <div class="mx-auto mt-0 w-full col-span-2">
                        <dl class="mt-16 grid grid-cols-2 gap-8 sm:mt-16 lg:grid-cols-4">
                            <div class="flex flex-col-reverse">
                                <dt class="text-base leading-7 text-gray-900">Ratio students to tutor</dt>
                                <dd class="text-2xl font-bold leading-9 tracking-tight text-education-space">10:1</dd>
                            </div>
                            <div class="flex flex-col-reverse">
                                <dt class="text-base leading-7 text-gray-900">of STEM and Space fun</dt>
                                <dd class="text-2xl font-bold leading-9 tracking-tight text-education-space">Space</dd>
                            </div>
                            <div class="flex flex-col-reverse">
                                <dt class="text-base leading-7 text-gray-900">long workshop</dt>
                                <dd class="text-2xl font-bold leading-9 tracking-tight text-education-space">4 hours</dd>
                            </div>
                            <div class="flex flex-col-reverse">
                                <dt class="text-base leading-7 text-gray-900">fully trained and enhanced-DBS</dt>
                                <dd class="text-2xl font-bold leading-9 tracking-tight text-education-space">Meet real engineers</dd>
                            </div>
                        </dl>
                    </div> */}
                    <Trustpilot />
                </div>
            </div>
            <div class="relative isolate overflow-hidden bg-[#F3F3F3] mx-auto bg-pattern-transparent-space-10 bg-contain bg-repeat" id="sessions" >
                <div class="relative isolate overflow-hidden py-12 sm:py-24 mx-auto max-w-7xl">
                    <div class="px-6 lg:px-8">
                        <div class="mx-auto lg:mx-0 grid grid-cols-5 w-full">
                            <div className="col-span-4">
                                <h1 class="text-4xl font-bold tracking-tight text-education-main sm:text-5xl font-chillax_semibold">Find a session</h1>
                                <p class="mt-6 text-lg leading-8 text-gray-800 max-w-2xl font-synonym_medium">Ready to launch your child's curiosity? Explore our upcoming sessions and secure a spot for your little explorer. </p>
                            </div>
                            <div className="col-span-1">
                                <h1 class="text-4xl font-bold tracking-tight text-white/70 sm:text-5xl"><img src="../assets/images/icon-y.png" className="w-32 mt-4" /></h1>
                            </div>
                        </div>
                        <div class="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none font-chillax_regular">
                            <div className="grid grid-cols-3 text-left gap-x-8 pb-4">
                                <button
                                    className={`block p-4 rounded-xl font-synonym_medium hover:bg-education-main-text hover:text-education-main-btn duration-200 ${activeTabLocation === 'workshops' ? 'hover:text-education-white bg-education-main-text text-education-main-btn' : 'p-4 border-education-main-text rounded-xl text-education-main-text border-2'}`}
                                    onClick={() => handleLocationTabClick('workshops')}
                                    role="tab"
                                    aria-controls="workshops"
                                    aria-selected={activeTabLocation === 'workshops'}
                                >
                                    Workshops
                                </button>
                                <button
                                    className={`block p-4 rounded-xl font-synonym_medium hover:bg-education-main-text hover:text-education-main-btn duration-200 ${activeTabLocation === 'holiday-camps' ? 'hover:text-education-white bg-education-main-text text-education-main-btn' : 'p-4 border-education-main-text rounded-xl text-education-main-text border-2'}`}
                                    onClick={() => handleLocationTabClick('holiday-camps')}
                                    role="tab"
                                    aria-controls="holiday-camps"
                                    aria-selected={activeTabLocation === 'holiday-camps'}
                                >
                                    Holiday Camps
                                </button>
                                <a
                                    className="block p-4 rounded-xl font-synonym_medium border-education-main-text  text-education-main-text border-2 text-center hover:bg-education-main-text hover:text-education-main-btn duration-200"
                                    href="/birthday-party-kids"
                                >
                                    Birthday Parties
                                </a>
                            </div>
                            {activeTabLocation === 'workshops' && (
                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-education-space sm:grid-cols-1 lg:gap-x-10">
                                    {/* <SessionBlock
                                        iconhome={<img width="50" height="50" src="https://img.icons8.com/ios/50/000000/rocket--v1.png" alt="rocket--v1" className="" />}
                                        name="Build a rocket"
                                        courseinfo="/build-a-rocket"
                                        agegroup="4 - 11"
                                        date="Saturday, 8th June 2024"
                                        location="St. Peter's C.E. Primary School, Hammersmith"
                                        starttime="9am"
                                        endtime="12pm"
                                        booknow={true}
                                        booknowlink="https://buy.stripe.com/fZeeYXgUCcPpg2AeV5"
                                        label="cheaper"
                                        page="home"
                                        cost="35"
                                    /> */}
                                    {/* <SessionBlock
                                        iconhome={<img width="50" height="50" src="https://img.icons8.com/ios/50/000000/rocket--v1.png" alt="rocket--v1" className="" />}
                                        name="Build a rocket"
                                        courseinfo="/build-a-rocket"
                                        agegroup="4 - 11"
                                        date="Saturday, 28th September 2024"
                                        location="Fulham Cross Academy"
                                        starttime="9am"
                                        endtime="1pm"
                                        booknow={true}
                                        booknowlink="https://buy.stripe.com/fZeeYXgUCcPpg2AeV5?prefilled_promo_code=JULY20"
                                        label="longer"
                                        page="home"
                                        cost="55"
                                        promo="44"
                                    /> */}
                                    <div className="grid grid-cols-1 md:grid-cols-1 pl-5 mt-2 p-8 bg-white rounded-lg">
                                        <div className="text-center text-xl">
                                            We currently don't have available workshops.
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTabLocation === 'holiday-camps' && (
                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-education-space sm:grid-cols-1 lg:gap-x-10">
                                    {/* <SessionBlock
                                        iconhome={<img width="50" height="50" src="https://img.icons8.com/ios/50/astronaut.png" alt="astronaut" />}
                                        name="Astronaut Camp"
                                        agegroup="4 - 9"
                                        date="From 5th August to 11th August"
                                        location="St. Peter's C.E. Primary School, Hammersmith"
                                        starttime="TBC"
                                        endtime="TBC"
                                        notifyme={true}
                                        camp={true}
                                    />
                                    <SessionBlock
                                        iconhome={<img width="50" height="50" src="https://img.icons8.com/ios/50/astronaut.png" alt="astronaut" />}
                                        name="Astronaut Camp"
                                        agegroup="4 - 9"
                                        date="From 12th August to 18th August"
                                        location="St. Peter's C.E. Primary School, Hammersmith"
                                        starttime="TBC"
                                        endtime="TBC"
                                        notifyme={true}
                                        camp={true}
                                    /> */}
                                    <div className="grid grid-cols-1 md:grid-cols-1 pl-5 mt-2 p-8 bg-white rounded-lg">
                                        <div className="text-center text-xl">
                                            We currently don't have available holiday camp.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div class="-z-10 relative isolate overflow-hidden bg-white mx-auto bg-contain bg-repeat" id="contact">
                <div class="relative isolate overflow-hidden py-12 sm:py-24 mx-auto max-w-7xl">
                    <div class="px-6 lg:px-8">
                        <div>
                            <p class="font-medium text-education-main font-chillax_medium">Contact us</p>

                            <h1 class="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white font-chillax_medium">Chat to our friendly team</h1>

                            <p class="mt-3 text-gray-500 dark:text-gray-400 font-synonym_regular">We'd love to hear from you. Please fill out this form or shoot us an email.</p>
                        </div>

                        <div class="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">
                            <div class="grid grid-cols-1 gap-12 md:grid-cols-2 text-md">
                                <div>
                                    <span class="inline-block p-3 text-education-main rounded-full bg-blue-100/80 dark:bg-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                        </svg>
                                    </span>

                                    <h2 class="mt-4 font-medium text-lg text-gray-800 dark:text-white font-chillax_medium">Email</h2>
                                    <p class="font-synonym_medium mt-2 text-md text-gray-500 dark:text-gray-400">Send us an email, we will reply within 48 hours.</p>
                                    <p class="mt-2 text-md text-education-main"><a href="mailto:hi@louno.space">hi@louno.space</a></p>
                                </div>

                                <div>
                                    <span class="inline-block p-3 text-education-main rounded-full bg-blue-100/80 dark:bg-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                        </svg>
                                    </span>

                                    <h2 class="mt-4 text-lg font-medium text-gray-800 dark:text-white font-chillax_medium">Live chat</h2>
                                    <p class="font-synonym_medium mt-2 text-md text-gray-500 dark:text-gray-400">Start a chat if you need a quicker response.</p>
                                    <p class="mt-2 text-md text-education-main"><a href="#hs-chat-open">Start new chat</a></p>
                                </div>

                                <div>
                                    <span class="inline-block p-3 text-education-main rounded-full bg-blue-100/80 dark:bg-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                        </svg>
                                    </span>

                                    <h2 class="mt-4 text-lg font-medium text-gray-800 dark:text-white font-chillax_medium">Location</h2>
                                    <p class="font-synonym_medium mt-2 text-md text-gray-500 dark:text-gray-400">Our workshops take place in London.</p>
                                    <p class="mt-2 text-md text-education-main"><a href="#sessions">Come visit us</a></p>
                                </div>

                                <div>
                                    <span class="inline-block p-3 text-education-main rounded-full bg-blue-100/80 dark:bg-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                        </svg>
                                    </span>

                                    <h2 class="mt-4 text-lg font-medium text-gray-800 dark:text-white font-chillax_medium">Phone</h2>
                                    <p class="font-synonym_medium mt-2 text-md text-gray-500 dark:text-gray-400">Feel free to call us or send us a SMS. We're also on WhatsApp.</p>
                                    <p class="mt-2 text-md text-education-main"><a href="tel:+4407873621402">07873 62 14 02</a></p>
                                </div>
                            </div>

                            <div class="p-4 py-6 rounded-lg bg-gray-50 dark:bg-gray-800 md:p-8">
                                <form onSubmit={handleSubmit}>
                                    <div class="-mx-2 md:items-center md:flex">
                                        <div class="flex-1 px-2">
                                            <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">First Name</label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                placeholder="John"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                            />
                                        </div>

                                        <div class="flex-1 px-2 mt-4 md:mt-0">
                                            <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">Last Name</label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                placeholder="Doe"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                            />
                                        </div>
                                    </div>

                                    <div class="mt-4">
                                        <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email address</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="johndoe@example.com"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                        />
                                    </div>

                                    <div class="w-full mt-4">
                                        <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">Message</label>
                                        <textarea
                                            name="message"
                                            placeholder="Message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                        ></textarea>
                                    </div>

                                    <button
                                        type="submit"
                                        className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-education-main rounded-lg hover:bg-education-pink-barbie focus:outline-none focus:ring focus:ring-education-pink-barbie focus:ring-opacity-50"
                                    >
                                        Send message
                                    </button>
                                    <span className="text-education-ming">{message}</span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ModalEnquireNow
                isOpen={enquireNowModalOpen}
                onClose={() => setEnquireNowModalOpen(false)}
            />

            {isOpenVideo && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                    <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50" onClick={closePopup}></div>
                    <div className="relative bg-white rounded-lg p-8 w-full md:w-[70%]">
                        <button onClick={closePopup} className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-800">
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                        <div className="aspect-w-16 aspect-h-9">
                            <iframe src="https://www.youtube.com/embed/S6iiz3LzF1U?si=pqu_39_uHmX9bu45" width="100%" className="w-full aspect-video rounded-2xl" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>

            )}

            <ModalNotifyWorkshop
                isOpen={modalData.isOpen}
                onClose={closeModal}
                workshopDate={modalData.dateWorkshop}
                workshop="build a rocket"
            />
        </>
    );
}

export default Banner3;
