import React, { useState, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import AOS from 'aos';
import "aos/dist/aos.css";
import Cookies from 'js-cookie';
import { MdOutlineScience } from "react-icons/md";
import { useSpring, animated } from 'react-spring';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Iframe from 'react-iframe'
import ModalNotifyWorkshop from '../../utils/ModalNotifyWorkshop'
import ModalWaitinglist from '../../utils/ModalWaitingList'
import TrustBoxContainer from '../../utils/Trustpilot';
import Footer from '../common/Footer';
import Header from '../common/Header';
import FeedbackButton from '../../utils/FeedbackButton'
const FAQs = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [message, setMessage] = useState('');

    const [activeTab, setActiveTab] = useState('general');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleFAQClick = (faq) => {
        setfaqView(faq);
    };
    const [faqView, setfaqView] = useState('faq1');

    useEffect(() => {
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenWidth();

        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobile(prevState => !prevState);
    };

    const [isNavOpen, setIsNavOpen] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/f895927d-67e1-4488-8fdc-a56fd255d8aa';

        // Example request JSON:
        var data = {
            submittedAt: Date.now(),
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: formData.email,
                }
            ],
            context: {
                hutk: Cookies.get('hubspotutk'),
            },
        };

        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage("You're in! You will receive an email with more details soon. Thank you 🚀");
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    return (
        <>
            <div className="bg-white">
                <Header />
                <FeedbackButton page="faq"/>
                <div className="bg-gradient-to-r from-[#ff4b14] to-[#ffb143] bg-gradient-72 bg-cover bg-bottom bg-no-repeat h-auto relative font-synonym_medium">
                    <div className="h-full mx-auto bg-cover">
                        <div className="grid grid-cols-1 h-full p-4 text-center">
                            <div className="flex items-center justify-center p-4 md:p-8 text-white w-full md:w-full">
                                <div>
                                    <h1 className="text-3xl md:text-5xl font-chillax_semibold">Find the answers to your questions</h1>
                                    <p className="pt-4 pb-8 w-[60%] mx-auto">If you still need help call us for any assistance on 07873 62 14 02 or email us at <a href="mailto:hi@louno.space" className="text-education-black">hi@louno.space</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative isolate overflow-hidden bg-education-white mx-auto" id="faqs">
                    <div class="relative isolate overflow-hidden py-12 sm:py-24 mx-auto max-w-7xl">
                        <div class="px-6 lg:px-8">
                            <div class="mx-auto lg:mx-0 grid grid-cols-5 w-full">
                                <div className="col-span-4">
                                    <h1 class="text-4xl font-bold tracking-tight text-education-turquoise sm:text-5xl font-chillax_semibold">Got questions?</h1>
                                    <p class="mt-6 text-lg leading-8 text-gray-800 max-w-2xl font-synonym_medium">We know you have questions, we tried to answer them all. If you would like to ask something else, please get in touch.</p>
                                </div>
                                <div className="col-span-1">
                                    <h1 class="text-4xl font-bold tracking-tight text-white/70 sm:text-5xl"><img src="../assets/images/LSE_Dark.png" className="w-32 mt-4" /></h1>
                                </div>
                            </div>
                            <div class="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                                <div class="mt-8 xl:mt-16 lg:flex lg:-mx-12">
                                    <div class="lg:mx-12">
                                        <h1 class="text-xl font-semibold text-gray-800 dark:text-white">Topic</h1>
                                        <div class="mt-4 space-y-4 lg:mt-8">
                                            <button
                                                className={`block hover:text-education-turquoise font-synonym_medium ${activeTab === 'general' ? 'text-education-turquoise-dark' : 'text-gray-400'}`}
                                                onClick={() => handleTabClick('general')}
                                                role="tab"
                                                aria-controls="general"
                                                aria-selected={activeTab === 'general'}
                                            >
                                                General
                                            </button>
                                            <button
                                                className={`block hover:text-education-turquoise font-synonym_medium ${activeTab === 'workshops' ? 'text-education-turquoise-dark' : 'text-gray-400'}`}
                                                onClick={() => handleTabClick('workshops')}
                                                role="tab"
                                                aria-controls="workshops"
                                                aria-selected={activeTab === 'workshops'}
                                            >
                                                Workshops
                                            </button>
                                            <button
                                                className={`block hover:text-education-turquoise font-synonym_medium ${activeTab === 'payment' ? 'text-education-turquoise-dark' : 'text-gray-400'}`}
                                                onClick={() => handleTabClick('payment')}
                                                role="tab"
                                                aria-controls="payment"
                                                aria-selected={activeTab === 'payment'}
                                            >
                                                Payment and Offers
                                            </button>
                                            <button
                                                className={`block hover:text-education-turquoise font-synonym_medium ${activeTab === 'refunds' ? 'text-education-turquoise-dark' : 'text-gray-400'}`}
                                                onClick={() => handleTabClick('refunds')}
                                                role="tab"
                                                aria-controls="refunds"
                                                aria-selected={activeTab === 'refunds'}
                                            >
                                                Refunds and Cancellations
                                            </button>
                                        </div>
                                    </div>
                                    {activeTab === 'general' && (
                                        <div class="flex-1 mt-8 lg:mx-12 lg:mt-0">
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('g1f1')}
                                                    role="tab"
                                                    aria-controls="g1f1"
                                                    aria-selected={faqView === 'g1f1'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'g1f1' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        What programs do you offer?
                                                    </button>
                                                </button>

                                                {faqView === 'g1f1' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            We offer face-to-face workshops for kids aged 5 to 8. We believe that one way to inspire children is to let me experience things with their hands. We want our students to touch things, to craft stuff and to engage with peers for support or a laugh. We also believe that being guided by a real space engineer can help them set role models and ambitions for their future.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>

                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button class="flex items-center focus:outline-none">
                                                    {faqView === 'g1f2' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button
                                                        onClick={() => handleFAQClick('g1f2')}
                                                        role="tab"
                                                        aria-controls="g1f2"
                                                        aria-selected={faqView === 'g1f2'}
                                                        class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        What ages are your programs appropriate for?
                                                    </button>
                                                </button>

                                                {faqView === 'g1f2' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            We currently teach students aged 5 to 8.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button class="flex items-center focus:outline-none">
                                                    {faqView === 'g1f3' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button
                                                        onClick={() => handleFAQClick('g1f3')}
                                                        role="tab"
                                                        aria-controls="g1f3"
                                                        aria-selected={faqView === 'g1f3'}
                                                        class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        Where do the workshops take place?
                                                    </button>
                                                </button>

                                                {faqView === 'g1f3' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            The workshops take place in Hammersmith London. The official location is yet to be confirmed, but you will receive all information way before the start of the workshop.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button class="flex items-center focus:outline-none">
                                                    {faqView === 'g1f4' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button
                                                        onClick={() => handleFAQClick('g1f4')}
                                                        role="tab"
                                                        aria-controls="g1f4"
                                                        aria-selected={faqView === 'g1f4'}
                                                        class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        Who are the tutors?
                                                    </button>
                                                </button>

                                                {faqView === 'g1f4' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            All tutors are engineers who work in the Aerospace or Space and Defence fields. Some of them are software engineers, other mechanicals or electronics, some others are systems engineers. All our tutors are enhanced DBS-checked, are STEM Ambassadors and have experience working with children.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button class="flex items-center focus:outline-none">
                                                    {faqView === 'g1f5' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button
                                                        onClick={() => handleFAQClick('g1f5')}
                                                        role="tab"
                                                        aria-controls="g1f5"
                                                        aria-selected={faqView === 'g1f5'}
                                                        class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        Do you do birthday parties?
                                                    </button>
                                                </button>

                                                {faqView === 'g1f5' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            Yes! We are glad you asked, we love space, teaching children and birthday cake. To request a quote, please send un an email at <a href="mailto:hi@louno.space" className="text-education-turquoise">hi@louno.space</a>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button class="flex items-center focus:outline-none">
                                                    {faqView === 'g1f6' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button
                                                        onClick={() => handleFAQClick('g1f6')}
                                                        role="tab"
                                                        aria-controls="g1f6"
                                                        aria-selected={faqView === 'g1f6'}
                                                        class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        Do you do any online sessions?
                                                    </button>
                                                </button>

                                                {faqView === 'g1f6' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            No, at the moment we do not host any online sessions. However, we are actively working on some online activities, so... stay tuned!
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button class="flex items-center focus:outline-none">
                                                    {faqView === 'g1f7' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button
                                                        onClick={() => handleFAQClick('g1f7')}
                                                        role="tab"
                                                        aria-controls="g1f7"
                                                        aria-selected={faqView === 'g1f7'}
                                                        class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        What happens once I have booked?
                                                    </button>
                                                </button>

                                                {faqView === 'g1f7' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            After your booking is successful, we will send you a confirmation email with all the workshop's details. We will also ask you to fill up a form with information about your child: name, age, allergies, etc.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === 'workshops' && (
                                        <div class="flex-1 mt-8 lg:mx-12 lg:mt-0">
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('w1f1')}
                                                    role="tab"
                                                    aria-controls="w1f1"
                                                    aria-selected={faqView === 'w1f1'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'w1f1' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        Will my kid receive a certificate?
                                                    </button>
                                                </button>

                                                {faqView === 'w1f1' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            Yes! Not only they will learn and build something with their hards, but they will also receive a certificate saying what they achieved - we recommend you collect them all.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('w1f2')}
                                                    role="tab"
                                                    aria-controls="w1f2"
                                                    aria-selected={faqView === 'w1f2'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'w1f2' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        What do they need to bring to camp?
                                                    </button>
                                                </button>

                                                {faqView === 'w1f2' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            We suggest:
                                                            <ul class="space-y-1 text-gray-500 list-disc list-inside">
                                                                <li>Comfortable clothing and trainers if possible</li>
                                                                <li>A snack and lunch for a morning session</li>
                                                                <li>An afternoon snack for an afternoon session</li>
                                                                <li>A bottle of water</li>
                                                            </ul>
                                                            <br /><br />
                                                            We will provide fresh fruit, water, squash and juices.
                                                            <br />
                                                            <span className="font-synonym_semibold">⚠️ Please do not bring any nuts with you.</span>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('w1f3')}
                                                    role="tab"
                                                    aria-controls="w1f3"
                                                    aria-selected={faqView === 'w1f3'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'w1f3' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        My child has an allergy, how do I communicate it?
                                                    </button>
                                                </button>

                                                {faqView === 'w1f3' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            Don't worry, once your booking is confirmed, we will ask you to provide us with all the information about your child, including their allergy. We will make sure on the day that they are safe.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('w1f4')}
                                                    role="tab"
                                                    aria-controls="w1f4"
                                                    aria-selected={faqView === 'w1f4'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'w1f4' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        What is your staff to child ratio?
                                                    </button>
                                                </button>

                                                {faqView === 'w1f4' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            Our staff to child ratio is 1:10 maximum.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('w1f5')}
                                                    role="tab"
                                                    aria-controls="w1f5"
                                                    aria-selected={faqView === 'w1f5'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'w1f5' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        Do you accommodate workshops for children with disabilities?
                                                    </button>
                                                </button>

                                                {faqView === 'w1f5' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            Absolutely, all our workshops are designed for children with disabilities too. To ensure we can accommodate your access requirements, please send us an emailto <a href="mailto:hi@louno.space" className="text-education-main">hi@louno.space</a>.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === 'payment' && (
                                        <div class="flex-1 mt-8 lg:mx-12 lg:mt-0">
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('p1f1')}
                                                    role="tab"
                                                    aria-controls="p1f1"
                                                    aria-selected={faqView === 'p1f1'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'p1f1' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        Can I pay with childcare voucher?
                                                    </button>
                                                </button>

                                                {faqView === 'p1f1' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            No, sorry, we don't accept childcare vouchers, but we are in the process of accepting them. Stay tuned.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('p1f2')}
                                                    role="tab"
                                                    aria-controls="p1f2"
                                                    aria-selected={faqView === 'p1f2'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'p1f2' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        What are the payment methods?
                                                    </button>
                                                </button>

                                                {faqView === 'p1f2' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            We process the payment through <a href="https://stripe.com/gb">Stripe</a>, it is safe, easy and quick. We accept all credit and debit cards, including American Express, Google Pay and Apple Pay.
                                                            <br /><br />
                                                            If you would like to pay via Bank Transfer or any other way, please let us know via emailing <a className='text-education-turquoise' href="mailto:hi@louno.space">hi@louno.space</a>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('p1f3')}
                                                    role="tab"
                                                    aria-controls="p1f3"
                                                    aria-selected={faqView === 'p1f3'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'p1f3' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        Do you have any discounts/offers?
                                                    </button>
                                                </button>

                                                {faqView === 'p1f3' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            Yes! We have a few offers and discounts.
                                                            <ul class="space-y-1 text-gray-500 list-disc list-inside ">
                                                                <li>Use code "MOONLANDING" if you bring a sibling or a friend and you receive 10% off!</li>
                                                                <li>If you subscribe to our newsletter, you will receive another 5% discount!</li>
                                                                <li>We run promos often, make sure you follow us on our social media.</li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === 'refunds' && (
                                        <div class="flex-1 mt-8 lg:mx-12 lg:mt-0">
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('r1f1')}
                                                    role="tab"
                                                    aria-controls="r1f1"
                                                    aria-selected={faqView === 'r1f1'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'r1f1' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        Do you offer refunds?
                                                    </button>
                                                </button>

                                                {faqView === 'r1f1' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            Each refund request will be addressed individually, please send us an email at <a className='text-education-turquoise' href="mailto:hi@louno.space">hi@louno.space</a>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <hr class="my-8 border-gray-200 dark:border-gray-700" />
                                            <div>
                                                <button
                                                    onClick={() => handleFAQClick('r1f2')}
                                                    role="tab"
                                                    aria-controls="r1f2"
                                                    aria-selected={faqView === 'r1f2'}
                                                    class="flex items-center focus:outline-none"
                                                >
                                                    {faqView === 'r1f2' ? (
                                                        <svg class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                                                        </svg>
                                                    ) : (
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-education-turquoise" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}

                                                    <button class="mx-4 text-xl text-gray-700 dark:text-white font-chillax_medium">
                                                        What is the cancellation policy?
                                                    </button>
                                                </button>

                                                {faqView === 'r1f2' && (
                                                    <div class="flex mt-8 md:mx-10">
                                                        <span class="border border-education-turquoise"></span>
                                                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300 font-synonym_medium">
                                                            You will receive a full refund or a chance to defer to the next date if we cancel the event.
                                                            - You will always have a chance to defer to the next session if you can't make it, if you notify us within 1 week of the workshop date.
                                                            - Each cancellation request will be addressed individually, please send us an email at <a className='text-education-turquoise' href="mailto:hi@louno.space">hi@louno.space</a>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
}

export default FAQs;