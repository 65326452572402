import React, { useState, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import AOS from 'aos';
import "aos/dist/aos.css";
import Cookies from 'js-cookie';
import { MdOutlineScience } from "react-icons/md";
import { useSpring, animated } from 'react-spring';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Iframe from 'react-iframe'
import ModalNotifyWorkshop from '../../utils/ModalNotifyWorkshop'
import ModalWaitinglist from '../../utils/ModalWaitingList'
import TrustBoxContainer from '../../utils/Trustpilot';
import Footer from '../common/Footer';
import Header from '../common/Header';

const BritishScienceWeek = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenWidth();

        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobile(prevState => !prevState);
    };

    const [isNavOpen, setIsNavOpen] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/f895927d-67e1-4488-8fdc-a56fd255d8aa';

        // Example request JSON:
        var data = {
            submittedAt: Date.now(),
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: formData.email,
                }
            ],
            context: {
                hutk: Cookies.get('hubspotutk'),
            },
        };

        var final_data = JSON.stringify(data);

        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                setMessage("You're in! You will receive an email with more details soon. Thank you 🚀");
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                console.log(xhr.responseText); // Returns a 400 error if the submission is rejected.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
            }
        };

        xhr.send(final_data);
    };

    return (
        <>
            <div className="bg-white">
                <Header />
                <div className="bg-gradient-to-r from-[#ff4b14] to-[#ffb143] bg-gradient-72 bg-cover bg-bottom bg-no-repeat h-[500px] relative font-synonym_medium">
                    <div className="h-full mx-auto bg-cover">
                        <div className="grid grid-cols-1 h-full p-4 text-center">
                            <div className="flex items-center justify-center p-4 md:p-8 text-white w-full md:w-full">
                                <div>
                                    <h1 className="text-3xl md:text-5xl font-chillax_semibold">Celebrate British Science Week<br /> with <span className="text-education-pink-barbie">Louno Space</span>!</h1>
                                    <p className="pt-4 pb-8 w-[60%] mx-auto">British Science Week is a ten-day celebration of science, technology, engineering and maths that will place between 8 - 17 March 2024!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative isolate overflow-hidden bg-white py-12 sm:py-24 mx-auto max-w-7xl" id="about">
                    <div class="px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2">
                        <div className="mr-0 md:mr-4">
                            <div class="mx-auto max-w-2xl lg:mx-0 col-span-1 w-full">
                                <h1 class="text-4xl font-bold tracking-tight text-education-space sm:text-5xl font-chillax_medium">Calling all curious minds!</h1>
                                <p class="font-synonym_regular mt-6 text-lg leading-8 text-gray-900 text-justify">
                                    British Science Week is a ten-day celebration packed with exciting events and activities designed to ignite your passion for <span className="text-education-main font-synonym_medium">science, technology, engineering, and maths (STEM)</span>! 
                                    <br/><br/>
                                    From <span className="text-education-main font-synonym_medium">March 8th to 17th, 2024</span>, there's something for everyone, whether you're a seasoned science enthusiast or just starting your exploration.  This year's theme is "Time!", inviting you to delve into the fascinating world of time, its history, its impact on our lives, and the mysteries that surround it.
                                    <br/><br/>
                                    Dive into a world of discovery:
                                    <br/>
                                    <ul class="md:space-y-4 text-gray-500 list-disc list-inside">
                                    <li>Interactive workshops and demonstrations: Led by passionate scientists and experts, these workshops will bring the wonders of STEM to life.</li>
                                   <li> Hands-on experiments and challenges: Get ready to roll up your sleeves and participate in engaging activities designed to spark curiosity and ignite a love for learning.</li>
                                    <li>Educational talks and discussions: Explore diverse scientific topics through informative talks and stimulating discussions with leading figures in the field.</li>
                                    <li>Family-friendly events and activities: Make learning about science a fun and exciting experience for all ages with activities designed for the whole family.</li>
                                    </ul>
                                </p>
                                <br /><br />
                                {/* <a href="#register" className="p-4 text-education-white bg-education-pink-barbie rounded-lg text-xl w-full mx-auto font-synonym_medium">Register now!</a> */}
                                <br /><br /><br />
                                {/* <div class="grid grid-cols-2 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-education-space md:grid-cols-2 md:flex lg:gap-x-10 mt-8 bottom-0 align">
                                    <a href="/" className="hover:text-education-main duration-200">Next workshops <span aria-hidden="true">&rarr;</span></a>
                                    <a href="/" className="hover:text-education-main duration-200">FAQs <span aria-hidden="true">&rarr;</span></a>
                                    <a href="/" className="hover:text-education-main duration-200">About us <span aria-hidden="true">&rarr;</span></a>
                                </div> */}
                            </div>
                        </div>
                        <div className="ml-0 md:ml-8">
                            <p class="font-synonym_regular mt-6 text-lg leading-8 text-gray-900 text-justify">
                                <img src="/assets/images/Louno_Space_Webinar.png" className="rounded-3xl" />
                            </p>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
}

export default BritishScienceWeek;