// Carousel.js
import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// NextArrow.js
const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            {/* Your custom next arrow content (e.g., an SVG or an icon) */}
            <span>Next</span>
        </div>
    );
};

// PrevArrow.js
const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            {/* Your custom previous arrow content (e.g., an SVG or an icon) */}
            <span>Prev</span>
        </div>
    );
};


const Carousel = () => {
    const settings = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const FiveStars = (props) => {
        return (
            <>
                <div className="flex justify-center items-center space-x-1">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaElEQVR4nO2Zv2sUURDHP6KNoPgb/4CIaGFlZaMg2CQRYyuoIOlEG5t0FhKTndlDCxsrbUxxWlnZaGy01MrGRlE0hEC87LzzR+PKO2I4jj1375K73TX7hYGDnZk3n5v3Cx5UqlSpLzWFc055aYJzSpynma9BmG8qZ3uCMGE27+Jdd6jpXjoRF9oCxlNB/HTKvVBNtRfpIIIVoNA4xaIsHYnLYFQgusk7YsIbU97+DyDXTLleahBTfkUh+1dus8+En6UFccLjtrxPygsSMNqWd6yUIKZ8iets/ZvX/zblcxlBZgZ5GWVYIFHIkc7cKyGHTfhdGhATXnXLb8rrYoAIc06YMuGqUy42QyZMOd0MOd6occjucjCusb1bfv/N+3hfH7MaO+Fzmc8pTLXGGALIUhRwigHpu3DClIWhTC1/0LmAKxsNYcIFU34MfY2YcD++ybb1ArS25h53NDYSZBXm2bc77O4XYilgpwlPh7/YNdHeJ223aWoII054l8+upV1t2WocywrhfX1Mv+MxQJDEQ7CbolmOrmcsBgViwtc4Zgs9yN/HCgfilEe9QLTGynDwuaGDhEwm5Vu8xw5viWOFTBYOpCGMdOaKlJN+RzPlg4Wc6fzurymFAjHhU3uO5Vl2+cMy4aZbb9TY2+5rwsfigCgP1uIDRj3YP3wXmsL5NpCHhQFxwqXGDHt8F3qIq0fTHHDK5cKAmHLDCYt9/AGLrdiigLgcjApE8++C25wdkRI89AgrWUDmSwDyPBXEPwHnXqim2hhZ5J+AC1BsnGQm3MoEsTbFAsb962kh1oxgfjpl7kSlSpXo1B/V1wlvyta18QAAAABJRU5ErkJggg==" />
                </div>
            </>
        )
    }

    const FiveStarsS = (props) => {
        return (
            <>
                <div className="flex space-x-1">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVR4nL2Vv0oDQRDGf1ioYBEE38DKLmDnH9CX0AewtEivRUpzs9cI+hJ5AYUUdj6G0c4ihnizwYjCyYQ7NInBJLfnwMAyO3wf38zsDvy3dRtUehesL+LdBpWpwEnEvjqevCMt4io8JjF7EwQhwH1O4miPgJu8UOA+c8P8Lk/MxozyP81nyTXM+QkcNyrclklwpBHH5RAIL2md1bTOsnd0ylBwled74boQgQoDjalpxGE/ZrsnbKaOtTzfzhazO8sZ5gqDeRV01HHw18t/jdnxwvNCJVLHhwqn08C940Qd78V7IFQnwIVqkCaro59esjJOYNNkdyGmqJUBLg2bGVOzc1aiVnGCiDObGC/c/VB1nzTY8sJ5CAVNdbz9UjqLNUM+tHQeHyEo/bs2s00UClyFB8bN1lwIEnW0E2F3giC30pZ+WfYFFKvjUmJzRiEAAAAASUVORK5CYII=" />
                </div>
            </>
        )
    }

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-3 mt-16">
                <div className="col-span-1 p-4">
                    <div className="bg-white shadow-md rounded-xl p-0 text-center items-center align-middle justify-center py-4 space-y-4">
                        <h3 className="font-chillax_medium text-4xl pb-2">Excellent !</h3>
                        <FiveStars />
                        <h4 className="font-synonym_regular">Based on <a href="https://uk.trustpilot.com/review/louno.space" className="font-synonym_medium border-b-2 border-black" target="_blank">Truspilot reviews</a></h4>
                        <img src="https://cdn.worldvectorlogo.com/logos/trustpilot-1.svg" className="w-32 mx-auto" />
                    </div>
                </div>
                <div className="col-span-2">
                    <Slider {...settings}>
                        <div className="p-4 w-full">
                            <div className="bg-white shadow-md rounded-xl p-4">
                                <div className="grid grid-cols-1 md:grid-cols-2">
                                    <div>
                                        <FiveStarsS />
                                    </div>
                                    <div>
                                        <p className="text-gray-500 text-[12px] text-right">22nd Jan. 2024</p>
                                    </div>
                                </div>
                                <h4 className="font-synonym_medium pt-8">The future is learning about space…</h4>
                                <p className="text-gray-500 py-4 text-sm">The future is learning about space exploration and this event covered so much in an easy to understand way, along with brilliant practical demos...</p>
                                <hr className="w-1/4" />
                                <h4 className="font-synonym_medium pt-4">Bev</h4>
                            </div>
                        </div>
                        <div className="p-4 w-full">
                            <div className="bg-white shadow-md rounded-xl p-4">
                                <div className="grid grid-cols-1 md:grid-cols-2">
                                    <div>
                                        <FiveStarsS />
                                    </div>
                                    <div>
                                        <p className="text-gray-500 text-[12px] text-right">22nd Jan. 2024</p>
                                    </div>
                                </div>
                                <h4 className="font-synonym_medium pt-8">Great STEM learning</h4>
                                <p className="text-gray-500 py-4 text-sm">Learning about space and building something is a great way to learn STEM. The practical demonstrations were cosmic. (Pun intended)</p>
                                <hr className="w-1/4" />
                                <h4 className="font-synonym_medium pt-4">Ashia</h4>
                            </div>
                        </div>
                        <div className="p-4 w-full">
                            <div className="bg-white shadow-md rounded-xl p-4">
                                <div className="grid grid-cols-1 md:grid-cols-2">
                                    <div>
                                        <FiveStarsS />
                                    </div>
                                    <div>
                                        <p className="text-gray-500 text-[12px] text-right">22nd Jan. 2024</p>
                                    </div>
                                </div>
                                <h4 className="font-synonym_medium pt-8">Ready to fly again!</h4>
                                <p className="text-gray-500 py-4 text-sm">TLouno space is an incredible service. The team are awesome (you could say out of this world!) they are really good working with children. Our Ivy loved every second. She CANNOT WAIT...</p>
                                <hr className="w-1/4" />
                                <h4 className="font-synonym_medium pt-4">Adam</h4>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default Carousel;
