import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { HeartIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import HeadTags from "./HeadTags";

const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenWidth();

        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);
    
    const toggleMobileMenuAbout = () => {
        setIsMobile(prevState => !prevState);
    };

    const toggleMobileMenu = () => {
        setIsMobile(prevState => !prevState);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const [isOpenAbout, setIsOpenAbout] = useState(false);

    const toggleDropdownAbout = () => {
        setIsOpenAbout(!isOpenAbout);
    };
    


    return (<>
        {/* Order HTML: general     sm      md      lg      xl      2xl */}
        <div className="bg-education-pink-barbie">
            <div className="p-4 text-education-white text-center mx-auto">
                <p className="font-synonym_medium">Bring a sibling or a friend for a 10% discount! Use code 'MOONLANDING' at checkout 🚀</p>
            </div>
        </div>
        <div className="bg-education-white px-0 py-4 text-education-black items-center mx-auto max-w-8xl">
            <div className="px-6 lg:px-8 flex justify-between items-center">
                <div className="flex items-center">
                    <a href="/">
                        <img src="/assets/images/Louno_Space_logo.png" className="w-48" alt="Logo" />
                    </a>
                </div>
                {isMobile ? (
                    <button className="lg:hidden focus:outline-none" onClick={() => setIsNavOpen((prev) => !prev)}>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                    </button>
                ) : (
                    <div className="flex gap-x-11 items-center">
                        <a href="/" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                            Home
                        </a>
                        <div className="relative">
                            <button
                                id="dropdownNavbarLink"
                                onClick={toggleDropdownAbout}
                                className="z-10 flex items-center justify-between w-full py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-education-main md:p-0 md:w-auto dark:text-gray-400 font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200"
                            >
                                About{' '}
                                <svg
                                    className="w-2.5 h-2.5 ms-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </button>

                            <div
                                id="dropdownNavbar"
                                className={`${isOpenAbout ? 'block' : 'hidden'
                                    } z-20 absolute font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}
                            >
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                    <li>
                                        <a href="/#about"
                                            className="block px-2 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                            Who we are 
                                        </a>
                                    </li>
                                </ul>
                                <div className="py-1">
                                    <a
                                        href="/our-education"
                                        className="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 "
                                    >
                                        Our Method
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="/#sessions" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                            Holiday Camps
                        </a>
                        {/* <div className="relative">
                            <button
                                id="dropdownNavbarLinkAbout"
                                onClick={toggleDropdownAbout}
                                className="z-10 flex items-center justify-between w-full py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-education-main md:p-0 md:w-auto dark:text-gray-400   dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200"
                            >
                                <a href="/#about">About{' '}</a>
                                <svg
                                    className="w-2.5 h-2.5 ms-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </button>

                            <div
                                id="dropdownNavbar"
                                className={`${isOpenAbout ? 'block' : 'hidden'
                                    } z-20 absolute font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}
                            >
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                    <li>
                                        <a
                                            href="/build-a-rocket"
                                            className="block px-2 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 "
                                        >
                                            Build a rocket 🚀
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="relative">
                            <button
                                id="dropdownNavbarLink"
                                onClick={toggleDropdown}
                                className="z-10 flex items-center justify-between w-full py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-education-main md:p-0 md:w-auto dark:text-gray-400   dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200"
                            >
                                Workshops{' '}
                                <svg
                                    className="w-2.5 h-2.5 ms-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </button>

                            <div
                                id="dropdownNavbar"
                                className={`${isOpen ? 'block' : 'hidden'
                                    } z-20 absolute font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}
                            >
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                    <li>
                                        <a href="/build-a-rocket"
                                            className="block px-2 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 "
                                        >
                                            Build a rocket 🚀
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a
                                            href="/build-0g-lab"
                                            className="block px-2 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 "
                                        >
                                            Build a 0G Lab ⚛️
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="/astronomy-101"
                                            className="block px-2 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 "
                                        >
                                            Astronomy 101 🌌
                                        </a>
                                    </li>
                                </ul>
                                <div className="py-1">
                                    <a
                                        href="/#sessions"
                                        className="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 "
                                    >
                                        See all
                                    </a>
                                </div>
                                <div className="py-1">
                                    <a
                                        href="/faqs"
                                        className="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 "
                                    >
                                        FAQs
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="/birthday-party-kids" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                            Birthday Party 🎈
                        </a>
                        <a href="/#contact" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                            Contact us
                        </a>
                        <a href="https://adventure.louno.space" className="font-semibold text-md leading-6 text-gray-900 hover:text-education-main duration-200">
                            Blog
                        </a>
                        <a href="https://www.facebook.com/groups/spaceforkids" target="_blank" class="self-end text-education-space font-semibold rounded-lg text-sm px-2 py-2.5 text-center me-2 mb-2 mt-2 border-education-main border-2 hover:bg-education-main hover:text-white duration-200">
                            Join the Community</a>
                    </div>
                )}
            </div>
            {isMobile && (
                <div className={`lg:hidden ${isMobile && isNavOpen ? 'block z-20 h-auto' : 'hidden'}`}>
                    {/* Mobile menu content goes here */}
                    <div className="px-6 py-8 bg-white text-center z-20 h-auto">
                        <a href="/" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                            Home
                        </a>
                        <a href="/#about" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                            About us
                        </a>
                        <a href="/#sessions" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                            Holiday Camps
                        </a>
                        <div className="relative">
                            <button
                                id="dropdownNavbarLink"
                                onClick={toggleDropdown}
                                className="z-10 flex items-center justify-between mx-auto px-3 rounded md:border-0 md:hover:text-education-main md:p-0 md:w-auto text-md leading-6  duration-200 py-2 text-gray-800 hover:text-education-main font-chillax_medium"
                            >
                                Workshops{' '}
                                <svg
                                    className="w-2.5 h-2.5 ms-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </button>

                            <div
                                id="dropdownNavbar"
                                className={`${isOpen ? 'block' : 'hidden'
                                    } absolute top-full left-0 mt-2 w-full font-normal bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 md:hidden z-50`}
                            >
                                <ul className="py-0 text-sm text-gray-700 dark:text-gray-200">
                                    <li>
                                        <a
                                            href="/build-a-rocket"
                                            className="block px-2 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 "
                                        >
                                            Build a rocket 🚀
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a
                                            href="/build-0g-lab"
                                            className="block px-2 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 "
                                        >
                                            Build a 0G Lab ⚛️
                                        </a>
                                    </li> */}
                                    <li>
                                        <a
                                            href="/astronomy-101"
                                            className="block px-2 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 "
                                        >
                                            Astronomy 101 🌌
                                        </a>
                                    </li>
                                </ul>
                                <div className="py-1">
                                    <a
                                        href="/#sessions"
                                        className="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 "
                                    >
                                        See all
                                    </a>
                                </div>
                                <div className="py-1">
                                    <a
                                        href="/faqs"
                                        className="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 "
                                    >
                                        FAQs
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="/birthday-party-kids" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                            Birthday Party 🎈
                        </a>
                        <a href="/#contact" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                            Contact us
                        </a>
                        <a href="https://adventure.louno.space" className="block py-2 text-gray-800 hover:text-education-main font-chillax_medium">
                            Blog
                        </a>
                        <br />
                        <a href="https://www.facebook.com/groups/spaceforkids" target="_blank" class="self-end text-education-space font-semibold rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 border-education-main border-2 hover:bg-education-main hover:text-white duration-200 ">
                            Join the Community</a>
                    </div>
                </div>
            )}
        </div>
    </>
    );
}

export default Header;
