import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const DiscountModal = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [emailAddress, setEmailAddress] = useState("")
    const [message, setMessage] = useState("")
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowPopup(true);
        }, 8000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Escape') {
            setShowPopup(false);
        }
    };

    const handleOverlayClick = (e) => {
        const modalContent = document.querySelector('.popup-content');

        if (modalContent && !modalContent.contains(e.target)) {
            setShowPopup(false);
        }
    };


    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        document.addEventListener('click', handleOverlayClick);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('click', handleOverlayClick);
        };
    }, []);



    const handleChangeEmailAddress = (event) => {
        const value = event.target.value;
        setEmailAddress(value);
    };

    const handleCloseModal = () => {
        setShowPopup(false);
    };

    const [selectedLocations, setSelectedLocations] = useState([]);

    const handleCheckboxChange = (location) => {
        setSelectedLocations((prevSelected) => {
            const isSelected = prevSelected.includes(location);

            if (isSelected) {
                // Remove the location from the string
                return prevSelected
                    .split(';')
                    .filter((selected) => selected !== location)
                    .join(';');
            } else {
                // Add the location to the string
                return prevSelected ? `${prevSelected},${location}` : location;
            }
        });
    };


    const locationsString = Array.isArray(selectedLocations) ? selectedLocations.join(';') : selectedLocations;

    const formv3 = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior

        setMessage("Loading..."); // Display loading message

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/143438058/b4ffc428-9e55-4730-aebb-d12e93b7467c'
        // Example request JSON:
        var data = {
            "submittedAt": Date.now(),
            "fields": [
                {
                    "objectTypeId": "0-1",
                    "name": "email",
                    "value": emailAddress
                }
            ],
            "context": {
                "hutk": Cookies.get('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
            }
        }

        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4 && xhr.status == 200) {
                setMessage("Subscribed. You will receive a coupon code within 24 hours. Thank you 🚀")
            } else if (xhr.readyState == 4 && xhr.status == 400) {
                setMessage("Error: Submission rejected."); // Display error message          
            } else if (xhr.readyState == 4 && xhr.status == 403) {
                setMessage("Error: Portal isn't allowed to post submissions."); // Display error message          
            } else if (xhr.readyState == 4 && xhr.status == 404) {
                setMessage("Error: FormGuid not found."); // Display error message     
            }
        }

        // Sends the request
        xhr.send(final_data)
        //console.log("Cookie" + Cookies.get("hubspotutk"))
    }



    return (
        <div className={`popup ${showPopup ? 'show' : ''}`}>
            {showPopup && (
                <div className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
                    <div className="popup-overlay"></div>
                    <div className="popup-content">
                        <div className="flex items-center justify-center h-full w-full">
                            <div className="bg-white rounded-lg max-w-2xl">
                                <section className="overflow-hidden rounded-lg shadow-2xl md:grid md:grid-cols-3">
                                    <img
                                        alt="Trainer"
                                        src="https://images.pexels.com/photos/7105873/pexels-photo-7105873.jpeg?auto=compress&cs=tinysrgb&w=800"
                                        className="h-32 w-full object-cover md:h-full"
                                    />

                                    <div className="p-4 text-center sm:p-6 md:col-span-2 lg:p-8">
                                        <p className="text-sm font-semibold uppercase tracking-widest">SUBSCRIBE TO OUR NEWSLETTER</p>

                                        <h2 className="mt-6 font-black uppercase">
                                            <span className="text-4xl font-black sm:text-5xl lg:text-6xl"> Get 5% off </span>
                                        </h2>
                                        <h2 className="mt-6 font-black uppercase">
                                            {/* <span className="leading-0 mb-0 text-4xl font-black sm:text-5xl lg:text-6xl"> Win a free<span className="text-3xl leading-0"> workshop session</span></span> */}
                                            <span className="mt-2 block text-sm">On your next workshop session</span>
                                        </h2>
                                        <span className="mt-2 normal-case font-normal text-sm">and be the first to learn about our upcoming camps, weekend classes and discount offers!</span>
                                        <div className="col-span-2 lg:col-span-3 lg:flex lg:items-end mt-16">
                                            <form className="w-full">
                                                <label htmlFor="UserEmail" className="sr-only"> Email </label>

                                                <div className="border border-gray-100 p-2  sm:flex sm:items-center sm:gap-4">
                                                    <input type="email" id="UserEmail" placeholder="yourname@louno.space"
                                                        className="w-full border-none focus:border-transparent focus:ring-transparent sm:text-sm rounded-md h-11 pl-4"
                                                        onChange={handleChangeEmailAddress} value={emailAddress} />

                                                    <button
                                                        className="mt-1 w-full bg-education-main px-6 py-3 text-sm font-bold uppercase tracking-wide text-white transition-none hover:bg-education-main sm:mt-0 sm:w-auto sm:flex-shrink-0 rounded-md focus:border-transparent"
                                                        onClick={(event) => formv3(event)} // Pass the event object
                                                        disabled={message === "Loading..."} // Disable the button when loading
                                                    >
                                                        {message === "Loading..." ? "Loading..." : "Sign Up"} {/* Display "Loading..." or "Sign Up" */}
                                                    </button>

                                                </div>
                                                <span className="text-xs">By submitting the form you agree to our <a href="policies">Terms and Conditions.</a></span>
                                                <br />
                                                <span className="text-education-ming">{message != "Loading..." ? message : ''}</span>
                                            </form>
                                        </div>
                                        {/* <p className="mt-8 text-xs font-medium uppercase text-gray-400">
                                            Offer valid until 31st January, 2024
                                        </p> */}
                                    </div>
                                </section>
                                <button
                                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                                    onClick={handleCloseModal}
                                >
                                    X
                                </button>
                            </div >
                        </div >
                    </div >
                </div >
            )}
        </div >
    );
};

export default DiscountModal;
