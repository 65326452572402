import React from "react";
import { Helmet } from "react-helmet";

const Smartlook = () => {
    return (
        <Helmet>
            <script id="facebook-pixel-script">
                {
                    `
                    window.smartlook||(function(d) {
                      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
                      var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
                      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
                      })(document);
                      smartlook('init', '4a92c500c3954a43d8e9bf9659cbedbbfc04e3c6', { region: 'eu' });
        `
                }</script>
        </Helmet>
    );
};

export default Smartlook;
