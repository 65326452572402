import React from "react";
import Helmet from "react-helmet";

const HeadTags = ({ pageTitle }) => {
    const fullTitle = pageTitle ? `Louno Space | ${pageTitle}` : "Louno Space";

    return (
        <Helmet>
            <title>{fullTitle}</title>
            <meta name="description" content="Join us in building your own rocket, a workshop in London taught by a real Space Engineer for kids aged 5-8." />

            <meta property="og:url" content="https://louno.space/" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={fullTitle} />
            <meta property="og:description" content="Join us in building your own rocket, a workshop in London taught by a real Space Engineer for kids aged 5-8." />
            <meta property="og:image" content="https://louno.space/assets/images/louno_space_sharer_image.png" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="louno.space" />
            <meta property="twitter:url" content="https://louno.space/" />
            <meta name="twitter:title" content={fullTitle} />
            <meta name="twitter:description" content="Join us in building your own rocket, a workshop in London taught by a real Space Engineer for kids aged 5-8." />
            <meta name="twitter:image" content="https://louno.space/assets/images/louno_space_sharer_image.png" />
        </Helmet>
    );
};

export default HeadTags;