import React, { Component } from 'react';
import Header from './Header';
import Loader from './Loader';
import Footer from './Footer';


class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  render() {
    return (
      <div className="isolate bg-education-white">
        {this.state.loading && <Loader />}
        {!this.state.loading && <Header />}
        {
          !this.state.loading && <>
            <div className="container-c mx-auto min-h-screen flex items-center p-5 lg:p-20 overflow-hidden relative">
              <div className="container-c mx-auto flex-1 min-h-full min-w-full rounded-3xl bg-education-white p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
                <div className="w-full md:w-1/2">
                  <div className="mb-10 md:mb-20 text-gray-600 font-light">
                    <h1 className="font-black uppercase text-3xl lg:text-5xl text-education-main">You seem to be lost!</h1>
                    <p>The page you're looking for isn't available.</p>
                    <p className="mt-20">
                      This is a very interesting space fact, did you know? 
                    </p>
                  </div>
                  <div className="mb-20 md:mb-0">
                    <a href="/"><button className="text-lg font-light outline-none focus:outline-none transform transition-all hover:scale-110 text-education-main hover:text-education-ming"><i className="mdi mdi-arrow-left mr-2"></i>Go Home</button></a>
                  </div>
                </div>
                <div className="w-full md:w-1/2 text-center">
                 
                </div>
              </div>
              <div className="w-64 md:w-96 h-96 md:h-full bg-education-main bg-opacity-50 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform"></div>
              <div className="w-96 h-full bg-education-main bg-opacity-80 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform"></div>
            </div>

            </>
        }
        {!this.state.loading && <Footer />}
      </div>
    );
  }
}

export default ErrorPage;