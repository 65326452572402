import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import "aos/dist/aos.css";

const Policies = () => {
    return (
        <>
            <div class="relative isolate overflow-hidden bg-education-white">
                <Header />
                <div class="relative isolate overflow-hidden bg-white py-8 sm:py-12 mx-auto max-w-7xl">
                    <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
                        <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                            <Link className="text-sm text-education-black" to="/">Home</Link> - <span className="text-sm text-education-main">Privacy and Cookie Policy</span>
                            <header className="mb-4 lg:mb-6 not-format mt-10">
                                {/* <address className="flex items-center mb-6 not-italic">
                                <div className="inline-flex items-center mr-3 text-sm text-education-black ">
                                    <img className="mr-4 w-16 h-16 rounded-full" src="/assets/images/team/mirkoviviano.jpg" alt="Mirko Viviano, CEO & Founder" />
                                    <div>
                                        <span className="text-xl font-bold text-education-black ">Mirko Viviano</span>
                                        <p className="text-base font-light text-gray-500 dark:text-gray-400">CEO & Founder, Louno Space Ltd.</p>
                                        <p className="text-base font-light text-gray-500 dark:text-gray-400">Feb. 1, 2023</p>
                                    </div>
                                </div>
                            </address> */}
                                <h1 className="mb-4 text-3xl font-extrabold leading-tight text-education-black lg:mb-6 lg:text-4xl ">Privacy and Cookie Policies</h1>
                            </header>
                            <p>
                                <span className="font-chillax_semibold text-xl">Workshop Policy</span>
                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">
                                    Registration and Payment:
                                </span>
                                <br />
                                <ul className="list-disc">
                                    <li className="py-1">To register for the workshop, please complete the online registration form on our website and make payment via Stripe. </li>
                                    <li className="py-1">Accepted payment methods include debit and credit cards (Visa, Mastercard, American Express).</li>
                                    <li className="py-1">Payment must be made in full to secure your child's spot in the workshop.</li>
                                    <li className="py-1">The registration fee is non-refundable, except in the event of workshop cancellation by the organizer.</li>
                                    <li className="py-1">If you cancel your registration more than 3 weeks before the workshop date, you will receive a full refund. Cancellations within 3 weeks of the workshop date will not be refunded.</li>
                                    <li className="py-1">If the workshop is canceled by the organizer, you will receive a full refund.</li>
                                    <li className="py-1">A limited number of spaces are available, and registration is on a first-come, first-served basis.</li>
                                    <li className="py-1">If the workshop is fully booked, you may be placed on a waitlist.</li>
                                    <li className="py-1">A 10% sibling discount is available for additional children from the same family or if you bring a friend.</li>
                                </ul>
                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Participation and Conduct:</span>
                                <br />
                                <ul className="list-disc">
                                    <li className="py-1">This workshop is designed for children ages 4 to 9.</li>
                                    <li className="py-1">All participants must be able to follow instructions and participate in group activities.</li>
                                    <li className="py-1">Disruptive behavior will not be tolerated. Parents or guardians may be asked to remove children who are unable to follow the workshop guidelines.</li>
                                    <li className="py-1">Participants are responsible for bringing their own snacks (no nuts, please). Drinks (squash, water and soft drinks) and fresh fruit will be provided.</li>
                                    <li className="py-1">Please note that the workshop will be hosted at a school, and all participants must adhere to the school's safety protocols and procedures.</li>
                                </ul>
                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">
                                    Liability and Limitations:
                                </span><br />
                                <ul className="list-disc">
                                    <li className="py-1">The workshop organizer is not responsible for any injuries, accidents, or lost or stolen belongings that may occur during the workshop.</li>
                                    <li className="py-1">Parents or guardians are responsible for the supervision of their children at all times.</li>
                                    <li className="py-1">The organizer reserves the right to modify or cancel the workshop due to unforeseen circumstances.</li>
                                </ul>
                                <br />
                                <span className="font-chillax_semibold text-md">
                                    Data Privacy:
                                </span><br />
                                By registering for this workshop, you consent to the collection and use of your personal information for the purposes of registration and communication.
                                Your personal information will not be shared with any third parties without your express consent.
                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">
                                    Agreement:
                                </span>
                                <br />
                                By registering for this workshop, you agree to abide by these terms and conditions.
                                Please read these terms and conditions carefully before registering for the workshop. If you have any questions, please contact us.
                                <br />
                                <br />
                                Thank you for your interest in our workshop! We look forward to seeing you there.
                                <br /><br /><br /><br />
                                <span className="font-chillax_semibold text-xl">Privacy Policy</span>

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Introduction</span><br />
                                We are committed to protecting the privacy of our website visitors and users. This Privacy Policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Data Collection</span><br />
                                We collect information from you when you fill out a form on our website. This information may include your name and email address. We use this information to send you updates and news about our services and products.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Google Analytics</span><br />
                                We use Google Analytics to gather information about how visitors interact with our website. This includes details such as which pages are visited and how long they are viewed for. The information collected by Google Analytics is anonymous and cannot be used to identify an individual user.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Data Storage and Processing</span><br />
                                We store and process your personal data in the UK, in accordance with applicable data protection and privacy laws.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Your Rights</span><br />
                                You have the right to request access to the personal data that we hold about you. You can also request the correction or deletion of your personal data.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Changes to this Policy</span><br />
                                We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons.

                                <br />
                                <br /><br />
                                <br />
                                <span className="font-chillax_semibold text-xl">Cookie Policy</span>

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Introduction</span><br />
                                We use cookies on our website to enhance your user experience and to gather information about how visitors interact with our website.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">What are Cookies?</span><br />
                                Cookies are small text files that are stored on your device when you visit a website. They are used to remember your preferences and to track your activity on the website.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Types of Cookies</span><br />
                                We use two types of cookies on our website: session cookies and persistent cookies. Session cookies are temporary and are deleted when you close your browser. Persistent cookies are stored on your device for a longer period of time.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Google Analytics Cookies</span><br />
                                We use Google Analytics to gather information about how visitors interact with our website. Google Analytics uses its own cookies to collect this information.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Managing Cookies</span><br />
                                You can control the use of cookies on our website by adjusting the settings in your browser. Most browsers allow you to reject all cookies, while others allow you to reject just third-party cookies.

                                <br />
                                <br />
                                <span className="font-chillax_semibold text-md">Changes to this Policy</span><br />
                                We may update this Cookie Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons.
                            </p>
                        </article>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
};

export default Policies;