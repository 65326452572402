import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ModalNotifyWorkshop from '../../utils/ModalNotifyWorkshop'
import ContactUsModal from '../../utils/ContactUsModal'
import Footer from '../common/Footer';
import Header from '../common/Header';
import HeadTags from "../common/HeadTags";

const Build0GLab = () => {
    // Using useParams hook to access the parameters from the route
    const { slug } = useParams();

    const [activeTab, setActiveTab] = useState('outcome');
    const [isModalOpen, setModalOpen] = useState(false);
    const [faqView, setfaqView] = useState('faq1');
    const [activeTabLocation, setActiveTabLocation] = useState('london');

    const handleLocationTabClick = (tab) => {
        setActiveTabLocation(tab);
    };
    const [modalData, setModalData] = useState({
        isOpen: false,
        dateWorkshop: '', // Add any other necessary modal data
    });

    const openModal = (date) => {
        setModalData({
            isOpen: true,
            dateWorkshop: date,
            // Set other modal data as needed
        });
    };

    const [isContactUsModalOpen, setContactUsModalOpen] = useState(false);

    const openContactUsModal = () => {
        setContactUsModalOpen(true);
    };

    const closeContactUsModal = () => {
        setContactUsModalOpen(false);
    };
    const closeModal = () => {
        setModalData({
            isOpen: false,
            dateWorkshop: '',
            // Set other modal data as needed
        });
    };

    const handleFAQClick = (faq) => {
        setfaqView(faq);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        AOS.init();
    }, [])

    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenWidth();

        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobile(prevState => !prevState);
    };

    return (
        <>
            <HeadTags pageTitle="Build 0G Lab" />
            <div class="relative isolate overflow-hidden bg-education-white">
                <Header />
                <div class="relative isolate overflow-hidden bg-white py-8 sm:py-12 mx-auto max-w-7xl">
                    <div class="px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2">
                        <div className="md:mr-4">
                            <div class="mx-auto max-w-2xl lg:mx-0">
                                <img src="/assets/images/build-rocket.png" className="rounded-xl" />
                            </div>
                        </div>
                        <div className="md:ml-8 mt-8 md:mt-0">
                            <div className="flex">
                                <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" />
                                <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" />
                                <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" />
                                <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" />
                                <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO2WvUrDUBTHf4OgKAp+7Cq46SsouFncHBQ/HkKfoE7W3NMOfQVnfYeOTn0EBxUcFGxzrs2gGLlgShBqY72JQvuHs+T+T345556bBEYaOllhWw33Voh9hAp3Vij1BX8avUC7cMNtloqz3uzGRVY/HsEHWuWwULAK0VOdmceAaTV0iqz4sus3XBVZ8W7i14C9QsAqvMTCVOKPy0yqwXoF6zmrrQqzSfTLTXtVWBscbGi2aqzwQ3WERTVc/7bVoVbZzwoNhR1rePa2x2q4cPvZKy8uM2EN9VyGSw1HvfLcWi5TrYa3sMpCr7z2GfMqvOZRcSPta9WYc/Elt5FHxceJRwM23VdMhQdr2OpeF068gyNhOS4zpsKpa3vqgd7dUMV1xqMKS37BhnYYsO7O9TcdaYbChvPm8sq0Awb/Fqx/+OtT8gl30PTkjzQ8+gA17e7SqaHSvwAAAABJRU5ErkJggg==" />
                            </div>
                            <h3
                                class="pt-4 mb-4 text-4xl font-bold tracking-tight text-education-space md:text-4xl font-chillax_medium align-bottom">
                                Build a 0G LAB <span className="text-xl">with space engineers!</span></h3>
                            <p className="font-synonym_regular text-justify pt-4">
                                This exciting workshop lets you experience the challenges and wonders of lunar exploration. Put on authentic astronaut gloves and delve into the Moon's simulated surface, searching for hidden clues. Discover what it takes to become a real space pioneer and join the next generation of <a alt="NASA Artemis heroes" target="_blank" href="https://www.nasa.gov/humans-in-space/artemis/">NASA Artemis heroes!</a>
                            </p>
                            <h3 class="font-bold tracking-tight text-education-space text-xl font-chillax_medium mt-20 mb-0">Ready to lunch to the Moon?</h3>
                            <div className="flex h-auto w-full mx-auto gap-x-8 mt-0">
                                <a href="#sessions"
                                    className="w-1/2 bg-education-main p-4 my-8 rounded-lg block text-education-white text-center uppercase">Book
                                    now</a>
                                <button type="button"
                                    className="w-1/2 bg-education-main-btn p-4 my-8 rounded-lg block text-education-main-text text-center uppercase"
                                    onClick={openContactUsModal}>Enquire <span aria-hidden="true">&rarr;</span></button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="mb-4 border-b border-gray-200 mt-8 w-full">
                            <ul className="flex flex-wrap -mb-px text-md font-medium text-center font-chillax_medium">
                                <li className="me-2 flex-grow" role="presentation">
                                    <button className={`inline-block w-full p-4 border-b-2 rounded-t-lg ${activeTab === 'outcome'
                                        ? 'border-education-main' : ''}`} onClick={() => handleTabClick('outcome')}
                                        role="tab"
                                        aria-controls="outcome"
                                        aria-selected={activeTab === 'outcome'}
                                    >
                                        Outcome
                                    </button>
                                </li>
                                <li className="me-2 flex-grow" role="presentation">
                                    <button className={`inline-block w-full p-4 border-b-2 rounded-t-lg ${activeTab === 'agenda'
                                        ? 'border-education-main' : ''}`} onClick={() => handleTabClick('agenda')}
                                        role="tab"
                                        aria-controls="agenda"
                                        aria-selected={activeTab === 'agenda'}
                                    >
                                        Agenda
                                    </button>
                                </li>
                                <li className="me-2 flex-grow" role="presentation">
                                    <button className={`inline-block w-full p-4 border-b-2 rounded-t-lg ${activeTab === 'faqs'
                                        ? 'border-education-main' : ''}`} onClick={() => handleTabClick('faqs')}
                                        role="tab"
                                        aria-controls="faqs"
                                        aria-selected={activeTab === 'faqs'}
                                    >
                                        FAQs
                                    </button>
                                </li>
                            </ul>

                            <div className="py-4 rounded-lg">
                                {activeTab === 'outcome' && (
                                    <p className="text-lg text-gray-500">
                                        <section class="bg-white antialiased font-synonym_regular px-8 md:px-0">
                                            <div class="max-w-screen-xl mx-auto">
                                                <div class="flow-root max-w-3xl mx-auto mt-8 font-synonym_regular">
                                                    <span>During this workshop we will build a model of a lunar laboratory. Little astronauts will wear gloves simulating what it feels like to be a real astronaut! Everything in space becomes more challenging, even using your hands.</span>
                                                    <br /><br />
                                                    <span className="font-synonym_medium">Here's what they can expect to gain:</span>
                                                    <div class="my-4 divide-y divide-gray-200">
                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">
                                                                Scientific Knowledge
                                                            </p>
                                                            <h3 class="text-lg text-gray-900">
                                                                Master the basics of STEM: Understanding key concepts in science, technology,
                                                                engineering, and math, all within the engaging context of space exploration.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">

                                                            </p>
                                                            <h3 class="text-lg text-gray-900">
                                                                Spark a love for learning: Cultivate a curiosity for scientific inquiry,
                                                                encouraging them to ask questions, experiment, and seek out new knowledge.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">

                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                Connect to the real world: Discover how STEM principles apply to everyday life,
                                                                building a foundation for problem-solving and critical thinking in all aspects
                                                                of their lives.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">
                                                                Confidence & Creativity
                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                Unleash their inner innovator: Building rockets, robots, and other cool
                                                                creations fosters a sense of confidence and accomplishment, empowering them to
                                                                believe in their own abilities.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">

                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                Think outside the box: Develop creative problem-solving skills by tackling
                                                                challenges and collaborating with others to find innovative solutions.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">

                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                Embrace individuality: Our workshops celebrate diversity and encourage kids to
                                                                explore their unique interests and talents, leaving them feeling valued and
                                                                empowered.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">
                                                                Collaboration Communication
                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                Teamwork makes the dream work: Learn the power of collaboration by working with
                                                                peers to build, solve problems, and achieve goals together.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">

                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                Embrace active listening and communication: Hone their communication skills by
                                                                explaining ideas, working through disagreements, and celebrating successes as a
                                                                team.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">

                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                Build lasting friendships: Foster a sense of community and belonging, creating
                                                                meaningful connections with like-minded peers who share their passion for STEM.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">
                                                                Lifelong Skills
                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                A growth mindset: Develop a love for learning and embrace challenges as
                                                                opportunities to grow, fostering resilience and a can-do attitude.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">
                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                Leadership potential: Older children learn to guide and mentor younger ones,
                                                                developing leadership skills like delegation, communication, and empathy.
                                                            </h3>
                                                        </div>

                                                        <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                            <p class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">

                                                            </p>
                                                            <h3 class="text-lg text-gray-900 d">
                                                                A passion for STEM: Spark an enduring love for science, technology, engineering,
                                                                and math, setting them on a path for future successes in these fields.
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </p>
                                )}

                                {activeTab === 'agenda' && (
                                    <section class="bg-white antialiased font-synonym_regular px-8 md:px-0">
                                        <p className="text-lg text-gray-500">
                                            <section class="bg-white antialiased">
                                                <div class="max-w-screen-xl mx-auto ">
                                                    <div class="flow-root max-w-3xl mx-auto mt-8">
                                                        <div class="-my-4 divide-y divide-gray-200 ">
                                                            <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                                <p
                                                                    class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">
                                                                    11:00 - 11:20
                                                                </p>
                                                                <h3 class="text-lg font-semibold text-gray-900 d">
                                                                    Opening remarks
                                                                </h3>
                                                            </div>

                                                            <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                                <p
                                                                    class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">
                                                                    11:20 - 11:35
                                                                </p>
                                                                <h3 class="text-lg font-semibold text-gray-900 d">
                                                                    Goals setting
                                                                </h3>
                                                            </div>

                                                            <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                                <p
                                                                    class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">
                                                                    11:35 - 12:00
                                                                </p>
                                                                <h3 class="text-lg font-semibold text-gray-900 d">
                                                                    Discussions about the Moon, astronauts and the challenges they face
                                                                </h3>
                                                            </div>

                                                            <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                                                                <p
                                                                    class="w-32 text-lg font-normal text-education-main-text sm:text-right shrink-0">
                                                                    12:00 - 15:00
                                                                </p>
                                                                <h3 class="text-lg font-semibold text-gray-900 d">
                                                                    Build the laboratory and practise what it takes to be an astronaut
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </p>
                                    </section>
                                )}
                                {activeTab === 'faqs' && (
                                    <section class="bg-white ">
                                        <div class="container max-w-4xl px-6 mx-auto">
                                            <div class="mt-12 space-y-8">
                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq1')}
                                                        role="tab"
                                                        aria-controls="faq1"
                                                        aria-selected={faqView === 'faq1'}>
                                                        <h1 class="font-semibold text-gray-700 d font-chillax_medium">
                                                            Who are the tutors?
                                                        </h1>

                                                        {faqView === 'faq1' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq1' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            All tutors are engineers who work in the Aerospace or Space and Defence fields. Some
                                                            of them are software engineers, other mechanicals or electronics, some others are
                                                            systems engineers. All our tutors are enhanced DBS-checked, are STEM Ambassadors and
                                                            have experience working with children.
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq2')}
                                                        role="tab"
                                                        aria-controls="faq2"
                                                        aria-selected={faqView === 'faq2'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            What happens once I have booked?
                                                        </h1>

                                                        {faqView === 'faq2' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq2' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            After your booking is successful, we will send you a confirmation email with all the
                                                            workshop's details. We will also ask you to fill up a form with information about
                                                            your child: name, age, allergies, etc.
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq3')}
                                                        role="tab"
                                                        aria-controls="faq3"
                                                        aria-selected={faqView === 'faq3'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            Will my kid receive a certificate?
                                                        </h1>

                                                        {faqView === 'faq3' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq3' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            Yes! Not only they will learn and build something with their hards, but they will
                                                            also receive a certificate saying what they achieved - we recommend you collect them
                                                            all.
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq4')}
                                                        role="tab"
                                                        aria-controls="faq4"
                                                        aria-selected={faqView === 'faq4'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            What do they need to bring to camp?
                                                        </h1>

                                                        {faqView === 'faq4' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq4' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            We suggest:
                                                            <ul class="space-y-1 text-gray-500 list-disc list-inside">
                                                                <li>Comfortable clothing and trainers if possible</li>
                                                                <li>A snack and lunch for a morning session</li>
                                                                <li>An afternoon snack for an afternoon session</li>
                                                                <li>A bottle of water</li>
                                                            </ul>
                                                            <br /><br />
                                                            We will provide fresh fruit, water, squash and juices.
                                                            <br />
                                                            <span className="font-synonym_semibold">⚠️ Please do not bring any nuts with you.</span>
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq5')}
                                                        role="tab"
                                                        aria-controls="faq5"
                                                        aria-selected={faqView === 'faq5'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            My child has an allergy, how do I communicate it?
                                                        </h1>

                                                        {faqView === 'faq5' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq5' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            Don't worry, once your booking is confirmed, we will ask you to provide us with all
                                                            the information about your child, including their allergy. We will make sure on the
                                                            day that they are safe.
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq6')}
                                                        role="tab"
                                                        aria-controls="faq6"
                                                        aria-selected={faqView === 'faq6'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            Do you accommodate workshops for children with disabilities?
                                                        </h1>

                                                        {faqView === 'faq6' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq6' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            Absolutely, all our workshops are designed for children with disabilities too.
                                                            Different arrangements would need to make, please send us an email with more details
                                                            at <a href="mailto:hi@louno.space"
                                                                className="text-education-main">hi@louno.space</a>.
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq7')}
                                                        role="tab"
                                                        aria-controls="faq7"
                                                        aria-selected={faqView === 'faq7'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            Can I pay with childcare voucher?
                                                        </h1>

                                                        {faqView === 'faq7' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq7' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            No, sorry, we don't accept childcare vouchers, but we are in the process of
                                                            accepting them. Stay tuned.
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq8')}
                                                        role="tab"
                                                        aria-controls="faq8"
                                                        aria-selected={faqView === 'faq8'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            What are the payment methods?
                                                        </h1>

                                                        {faqView === 'faq8' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq8' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            We process the payment through Stripe, it is safe, easy and quick. We accept all
                                                            credit and debit cards, including American Express, Google Pay and Apple Pay.

                                                            If you would like to pay via Bank Transfer or any other way, please let us know via
                                                            emailing <a href="mailto:hi@louno.space"
                                                                className="text-education-main">hi@louno.space</a>.
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq9')}
                                                        role="tab"
                                                        aria-controls="faq9"
                                                        aria-selected={faqView === 'faq9'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            Do you have any discounts/offers?
                                                        </h1>

                                                        {faqView === 'faq9' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq9' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            Yes! We have a few offers and discounts.
                                                            <ul class="space-y-1 text-gray-500 list-disc list-inside ">
                                                                <li>Use code "MOONLANDING" if you bring a sibling or a friend and you receive 10%
                                                                    off!</li>
                                                                <li>If you subscribe to our newsletter, you will receive another 5% discount!</li>
                                                                <li>We run promos often, make sure you follow us on our social media.</li>
                                                            </ul>
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq10')}
                                                        role="tab"
                                                        aria-controls="faq10"
                                                        aria-selected={faqView === 'faq10'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            Do you offer refunds?
                                                        </h1>

                                                        {faqView === 'faq10' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq10' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            Each refund request will be addressed individually, please send us an email at <a
                                                                href="mailto:hi@louno.space" className="text-education-main">hi@louno.space</a>.
                                                        </p>
                                                    )}
                                                </div>

                                                <div class="border-2 border-gray-100 rounded-lg ">
                                                    <button class="flex items-center justify-between w-full p-8" onClick={() =>
                                                        handleFAQClick('faq11')}
                                                        role="tab"
                                                        aria-controls="faq11"
                                                        aria-selected={faqView === 'faq11'}>
                                                        <h1 class="font-semibold text-gray-700 font-chillax_medium">
                                                            What is the cancellation policy?
                                                        </h1>

                                                        {faqView === 'faq11' ? (
                                                            <span class="text-gray-400 bg-gray-200 rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M18 12H6" />
                                                                </svg>
                                                            </span>
                                                        ) : (

                                                            <span class="text-white bg-education-main rounded-full">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none"
                                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                                </svg>
                                                            </span>
                                                        )}

                                                    </button>
                                                    <hr class="border-gray-200 " />

                                                    {faqView === 'faq11' && (
                                                        <p class="p-8 text-md text-gray-500  font-synonym_medium">
                                                            You will receive a full refund or a chance to defer to the next date if we cancel
                                                            the event. - You will always have a chance to defer to the next session if you can't
                                                            make it, if you notify us within 1 week of the workshop date. - Each cancellation
                                                            request will be addressed individually, please send us an email at <a
                                                                href="mailto:hi@louno.space" className="text-education-main">hi@louno.space</a>.
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative isolate overflow-hidden bg-education-ming py-12 sm:py-24 mx-auto" id="sessions">
                    <div className="mx-auto max-w-7xl">
                        <div class="px-6 lg:px-8">
                            <div class="mx-auto lg:mx-0 grid grid-cols-5 w-full">
                                <div className="col-span-4">
                                    <h1 class="text-4xl font-bold tracking-tight text-education-main sm:text-5xl font-chillax_semibold">
                                        Find a session</h1>
                                    <p class="mt-6 text-lg leading-8 text-education-white max-w-2xl font-synonym_medium">Ready to launch
                                        your child's curiosity? Explore our upcoming sessions and secure a spot for your little
                                        explorer.</p>
                                </div>
                                <div className="col-span-1">
                                    <h1 class="text-4xl font-bold tracking-tight text-white/70 sm:text-5xl"><img
                                        src="../assets/images/icon-y.png" className="w-32 mt-4" /></h1>
                                </div>
                            </div>
                            <div class="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none font-chillax_regular">
                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-education-space sm:grid-cols-1 lg:gap-x-10">
                                    <div className="grid grid-cols-4 text-left gap-x-8 pb-4">
                                        <button
                                            className={`block p-4 rounded-xl font-synonym_medium ${activeTabLocation === 'london' ? 'hover:text-education-white bg-education-main text-education-white' : 'p-4 border-education-white rounded-xl text-education-white border-2'}`}
                                            onClick={() => handleLocationTabClick('london')}
                                            role="tab"
                                            aria-controls="london"
                                            aria-selected={activeTabLocation === 'london'}
                                        >
                                            London
                                        </button>
                                        <button
                                            className={`block p-4 rounded-xl font-synonym_medium ${activeTabLocation === 'oxford' ? 'hover:text-education-white bg-education-main text-education-white' : 'p-4 border-education-white rounded-xl text-education-white border-2'}`}
                                            onClick={() => handleLocationTabClick('oxford')}
                                            role="tab"
                                            aria-controls="oxford"
                                            aria-selected={activeTabLocation === 'oxford'}
                                        >
                                            Oxford
                                        </button>
                                    </div>

                                    {activeTabLocation === 'london' && (
                                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-education-space sm:grid-cols-1 lg:gap-x-10">
                                            <div className="grid grid-cols-1 md:grid-cols-6 pl-5 mt-2 p-8 bg-white rounded-lg" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" >
                                                <div className="md:col-span-3 flex items-center">
                                                    <img width="50" height="50" src="https://img.icons8.com/ios/50/lab-items.png" alt="lab-items" className="" />
                                                    <div className="ml-8 pl-8 border-l-2 border-education-main">
                                                        <h3 className="font-synonym_medium leading-5 tracking-wide text-lg">Build your 0G Lab</h3>
                                                        <p className="text-gray-500 font-synonym_regular text-md">
                                                            <span className="text-gray-600">Age group</span> 4 - 9<br />
                                                            <span className="text-gray-600">Date</span> <span className="ml-4 text-gray-500">Saturday, 2nd March 2024</span> <br />
                                                            <span className="text-gray-600">Location</span> <span className="ml-2 text-gray-500"><a href="" alt="St. Peter’s C.E. Primary School, Hammersmith, London, W6 9BA" target="_blank" className="text-black">St. Peter’s C.E. Primary School, Hammersmith</a></span> <br />
                                                            <span className="text-gray-600">Duration</span> From 9am - 2pm
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="my-8 md:my-0 text-left md:tet-left md:col-span-1"></div>
                                                <div className="md:col-span-2 items-center justify-center grid grid-cols-2 gap-y-3">
                                                    <div>
                                                        {/* <a href="/build-0g-lab" className="border-2 border-education-main-text p-2 text-education-main-text rounded-lg opacity-75 font-synonym_medium text-sm uppercase hover:text-education-white hover:bg-education-main-text duration-200">Course info <span aria-hidden="true">&rarr;</span></a> */}
                                                    </div>
                                                    <div>
                                                        <a href="https://buy.stripe.com/dR69EDeMu9Dd9EceV0" className="border-2 border-education-pink-barbie p-2 text-education-pink-barbie rounded-lg opacity-75 font-synonym_medium text-sm uppercase hover:text-education-white hover:bg-education-pink-barbie duration-200">BOOK NOW <span aria-hidden="true">&rarr;</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-1 pl-5 mt-2 p-8 bg-white rounded-lg" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" >
                                                <div className="text-center text-xl">
                                                    More soon
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {activeTabLocation === "oxford" && (
                                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-education-space sm:grid-cols-1 lg:gap-x-10">
                                            <div className="grid grid-cols-1 md:grid-cols-6 pl-5 mt-2 p-8 bg-white rounded-lg" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" >
                                                <div className="md:col-span-3 flex items-center">
                                                    <img width="50" height="50" src="https://img.icons8.com/ios/50/lab-items.png" alt="lab-items" className="" />
                                                    <div className="ml-8 pl-8 border-l-2 border-education-main">
                                                        <h3 className="font-synonym_medium leading-5 tracking-wide text-lg">Build your 0G Lab</h3>
                                                        <p className="text-gray-500 font-synonym_regular text-md">
                                                            <span className="text-gray-600">Age group</span> 4 - 9<br />
                                                            <span className="text-gray-600">We are working hard to schedule a session for this workshop as soon as possible. Stay tuned for updates!</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="my-8 md:my-0 text-left md:tet-left md:col-span-1"></div>
                                                <div className="md:col-span-2 items-center justify-center grid grid-cols-2 gap-y-3">
                                                    <div>
                                                        {/* <a href="/build-0g-lab" className="border-2 border-education-main-text p-2 text-education-main-text rounded-lg opacity-75 font-synonym_medium text-sm uppercase hover:text-education-white hover:bg-education-main-text duration-200">Course info <span aria-hidden="true">&rarr;</span></a> */}
                                                    </div>
                                                    <div>
                                                        <a className="ml-8 p-3 rounded-lg bg-transparent border-2 border-education-pink-barbie text-education-pink-barbie hover:bg-education-pink-barbie hover:text-education-white duration-200 font-synonym_medium uppercase text-sm" onClick={() => openModal('25-02-2024', 'build-a-rocket-ox')}>Notify me <span aria-hidden="true">&rarr;</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-1 pl-5 mt-2 p-8 bg-white rounded-lg" data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" >
                                                <div className="text-center text-xl">
                                                    More soon
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalNotifyWorkshop
                    isOpen={modalData.isOpen}
                    onClose={closeModal}
                    workshopDate={modalData.dateWorkshop}
                    workshop="build a rocket"
                />
                <ContactUsModal isOpen={isContactUsModalOpen} onClose={closeContactUsModal} page="build-a-rocket" />
            </div >
            <Footer />
        </>
    )
};

export default Build0GLab;
